import React, { useContext } from "react"
import {
  DEFAULT_BLOCKOPTIONS_DEFAULTS,
  getItemFromId,
  ImageData,
} from "@rubendenbrok/bloqmatter-cms"
import getCascadingColor from "../../functions/getCascadingColor"
import useIsInScreen from "../../hooks/useIsInScreen"
import { fadeSlideIn } from "../../styles/animations"
import BloqmatterLink from "../atoms/BloqmatterLink"
import Button from "../atoms/Button"
import H2 from "../atoms/H2"
import TextBasic from "../atoms/TextBasic"
import { DataContext } from "../layout"
import H3 from "../atoms/H3"
import H1 from "../atoms/H1"
import TitleGroup from "../atoms/TitleGroup"
import { BLOCKOPTIONS_DEFAULTS } from "../../custom_blockoptions_inputs"
import { maxContentWidth } from "./DisplayBlock_Container"

type props = any

/*
Just a basic text box + title and CTA

DATA {
title : string
text : string (rich text html)
textAlign: 'start'|'left'|'center'
CTA Input group 
    default: 
    {buttonlink: Link, 
    buttonColor1: color, 
    buttonColor2: color}
*/

export default function TitleWithResponsiveTriangleBlock_Display({
  blockContent,
  blockIndex,
}: props) {
  const { cssVariables, thisPage, siteOverviewData, processedImageData } =
    useContext(DataContext)
  const [animRef, isVisible] = useIsInScreen("100px")
  const textAnimation = isVisible ? fadeSlideIn.show : fadeSlideIn.hide
  const imageObj = getItemFromId<ImageData>(
    blockContent.image,
    processedImageData
  )

  const heightFactor = 1.2
  const heightClass = "md:h-[calc(50vw*1.2)] h-[calc(75vw*1.2)]"
  const widthClass = "md:w-[50vw] w-[75vw]"

  const mobileTextAlignClass =
    blockContent.textAlignMobile === "end"
      ? "text-right"
      : blockContent.textAlignMobile === "start"
      ? "text-left"
      : "text-center"

  const textAlignClass =
    blockContent.textAlign === "start"
      ? `sm:text-left ${mobileTextAlignClass}`
      : blockContent.textAlign === "center"
      ? `sm:text-center ${mobileTextAlignClass}`
      : `sm:text-right ${mobileTextAlignClass}`

  return (
    <>
      {/* //DESKTOP VERSION */}
      <div
        ref={animRef}
        className={`flex md:flex-row flex-col-reverse  items-center ${
          blockContent.textAlign === "start"
            ? "items-start"
            : blockContent.textAlign === "center"
            ? "items-center"
            : "items-end"
        }`}
        style={{
          marginLeft: blockContent.textAlign === "start" ? 0 : "auto",
          marginRight: blockContent.textAlign === "end" ? 0 : "auto",
          ...fadeSlideIn.transition,
          ...textAnimation,
        }}
      >
        <div className="lg:w-1/2 md:w-2/3 w-full md:pl-20 pt-0 -mt-12 sm:-mt-32 md:mt-32 xl:mt-0 sm:pl-10 pl-6 pr-6 mb-20 md:mb-0">
          <TitleGroup
            blockContent={blockContent}
            textAlignClass={textAlignClass}
          />
          {blockContent.buttons &&
            blockContent.buttons.map((button: any, index: number) => {
              return (
                <Button
                  key={index}
                  link={button.buttonLink}
                  color1={cssVariables.colors[button.buttonColor1]}
                  color2={cssVariables.colors[button.buttonColor2]}
                  flip={blockContent.textAlign === "end"}
                ></Button>
              )
            })}
        </div>
        <div
          className={`lg:w-1/2 md:w-1/3 md:-translate-x-[16.66vw] lg:translate-x-0 w-full relative overflow-visible ${heightClass}`}
        >
          {imageObj && (
            <svg
              className={`absolute pointer-events-none top-0 md:left-0 right-0 overflow-visible ${widthClass} ${heightClass} `}
              viewBox={"0 0 8 9"}
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <defs>
                <pattern
                  id={`img-${imageObj.id}-${blockIndex}`}
                  width="100%"
                  height="100%"
                  patternUnits="userSpaceOnUse"
                  x="0"
                  y="0"
                >
                  <image
                    href={imageObj.urls.fallback.l} // which size to use?
                    x="0"
                    y="0"
                    width="100%"
                    height="100%"
                    preserveAspectRatio="xMidYMid slice"
                  />
                </pattern>
              </defs>

              <path
                d={
                  "M 8 0 L 1.183 0 q -0.885 -0.021 -0.973 0.83 Q 0.221 1.327 0.58 1.631 L 8 9"
                }
                fill={`url(#img-${imageObj.id}-${blockIndex})`}
              />
            </svg>
          )}
        </div>
      </div>
    </>
  )
}

export const TitleWithResponsiveTriangleBlock_inputs = {
  key: "titleWithResponsiveTriangleBlock",
  name: "Title With Triangle Image",
  description:
    "A title block that displays a title, with a triangular image to the right of it",
  tags: ["Title", "Text", "Image"],
  inputs: [
    { inputGroupName: "titleInputGroup", type: "group" },
    {
      inputType: "radio",
      labelArr: ["Left", "Center", "Right"],
      valueArr: ["start", "center", "end"],
      label: "Text align desktop",
      keyName: "textAlign",
      defaultVal: "center",
    },
    {
      inputType: "radio",
      labelArr: ["Left", "Center", "Right"],
      valueArr: ["start", "center", "end"],
      label: "Text align mobile",
      keyName: "textAlignMobile",
      defaultVal: "center",
    },
    { inputType: "image", keyName: "image", label: "Triangle image" },
    { type: "group", inputGroupName: "ctaInputGroup" },
  ],
  defaults: {
    ...BLOCKOPTIONS_DEFAULTS,
  },
}
