import React, { ReactNode } from "react"

type elementProps = {
  className?: string
  color?: string
  children?: ReactNode
  small?: boolean
  setInnerHtml?: string
}

export default function H3({
  className,
  color,
  children,
  small,
  setInnerHtml,
}: elementProps) {
  return (
    <h3
      style={{ color: color ? color : "inherit" }}
      className={`font-workSans  m-0 select-none  ${className} ${
        small ? "text-xl sm:text-2xl" : "text-xl sm:text-3xl"
      }`}
      dangerouslySetInnerHTML={
        setInnerHtml ? { __html: setInnerHtml } : undefined
      }
    >
      {children}
    </h3>
  )
}
