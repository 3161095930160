import React, { ReactNode } from "react"
import { SingleInputProps } from "@rubendenbrok/bloqmatter-cms"

export type BlockOption = {
  tag: string
  options: SingleInputProps[]
}

export const DEFAULT_BLOCKOPTIONS_VISIBILITY: BLOCKOPTIONS_VISIBILITY_TYPE = {
  visibleMobile: true,
  visibleDesktop: true,
}

type BLOCKOPTIONS_VISIBILITY_TYPE = {
  visibleMobile: boolean
  visibleDesktop: boolean
}

const BlockOptions_Colors_Inputs: SingleInputProps[] = [
  {
    inputType: "color",
    label: "Text Color",
    keyName: "colors_text",
    extraVals: ["auto"],
  },
  {
    inputType: "color",
    label: "Title Color",
    keyName: "colors_title",
    extraVals: ["auto"],
  },
  {
    inputType: "color",
    label: "Accent 1",
    keyName: "colors_accent1",
    extraVals: ["auto"],
  },
  {
    inputType: "color",
    label: "Accent 2",
    keyName: "colors_accent2",
    extraVals: ["auto"],
  },
  {
    inputType: "color",
    label: "Background Color",
    keyName: "colors_bg",
    extraVals: ["none"],
    extraLabels: ["None"],
  },
]

const BlockOptions_Spacing_Inputs: SingleInputProps[] = [
  {
    inputType: "slider",
    label: "Padding Top:",
    keyName: "spacing_paddingTop",
  },
  {
    inputType: "slider",
    label: "Padding Bottom:",
    keyName: "spacing_paddingBottom",
    min: -50,
  },
  {
    inputType: "radio",
    label: "Overlap next block:",
    keyName: "spacing_border_type",
    labelArr: ["None", "Slanted left", "Slanted right"],
  },
  {
    inputType: "slider",
    label: "Border Size:",
    keyName: "spacing_border_size",
  },
]

const BlockOptions_Bg_Inputs: SingleInputProps[] = [
  {
    inputType: "conditionalRadio",
    label: "Background type",
    keyName: "bg_type",
    labelArr: ["None", "Solid color", "Gradient", "Image", "Video"],
    valueArr: ["none", "color", "gradient", "image", "video"],
    childInputsArr: [
      [],
      [
        {
          inputType: "color",
          label: "Background Color",
          keyName: "colors_bg",
        },
      ],
      [
        {
          inputType: "gradient",
          label: "Background Gradient",
          keyName: "bg_gradient",
        },
      ],
      [
        {
          inputType: "image",
          label: "Background Image",
          keyName: "bg_image",
        },
        {
          inputType: "checkbox",
          label: "Repeat Background",
          keyName: "bg_repeat",
        },
        {
          inputType: "radio",
          label: "Scroll FX",
          keyName: "bg_scrollFx",
          labelArr: ["None", "Zoom", "Fixed", "Parallax"],
          valueArr: ["", "zoom", "fixed", "parallax"],
        },
      ],
      [{ inputType: "video", keyName: "bg_video", label: "Background video" }],
    ],
  },
]

const BLOCKOPTIONS: BlockOption[] = [
  {
    tag: "Colors",
    options: BlockOptions_Colors_Inputs,
  },
  {
    tag: "Spacing",
    options: BlockOptions_Spacing_Inputs,
  },
  {
    tag: "Background",
    options: BlockOptions_Bg_Inputs,
  },
]

export const BLOCKOPTIONS_DEFAULTS = {
  colors_text: "auto",
  colors_title: "auto",
  colors_accent1: "auto",
  colors_accent2: "auto",
  colors_bg: "none",
  spacing_paddingTop: 0,
  spacing_paddingBottom: 0,
  bg_image: "",
  bg_repeat: false,
  bg_parallax: false,
  spacing_border_type: 0,
  spacing_border_size: 0,
  ...DEFAULT_BLOCKOPTIONS_VISIBILITY,
}

export default BLOCKOPTIONS

export type BlockOptionsDefaults = {
  [x in keyof typeof BLOCKOPTIONS_DEFAULTS]: typeof BLOCKOPTIONS_DEFAULTS[x]
}
