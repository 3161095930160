import React, { useContext } from "react"
import { DEFAULT_BLOCKOPTIONS_DEFAULTS } from "@rubendenbrok/bloqmatter-cms"
import getCascadingColor from "../../functions/getCascadingColor"
import useIsInScreen from "../../hooks/useIsInScreen"
import { fadeSlideIn } from "../../styles/animations"
import BloqmatterLink from "../atoms/BloqmatterLink"
import Button from "../atoms/Button"
import H2 from "../atoms/H2"
import TextBasic from "../atoms/TextBasic"
import { DataContext } from "../layout"
import H3 from "../atoms/H3"
import H1 from "../atoms/H1"
import TitleGroup from "../atoms/TitleGroup"
import { BLOCKOPTIONS_DEFAULTS } from "../../custom_blockoptions_inputs"

type props = any

/*
Just a basic text box + title and CTA

DATA {
title : string
text : string (rich text html)
textAlign: 'start'|'left'|'center'
CTA Input group 
    default: 
    {buttonlink: Link, 
    buttonColor1: color, 
    buttonColor2: color}
*/

export default function BaiscTextBoxBlock_Display({ blockContent }: props) {
  const { cssVariables, thisPage, siteOverviewData } = useContext(DataContext)
  const [animRef, isVisible] = useIsInScreen("100px")
  const textAnimation = isVisible ? fadeSlideIn.show : fadeSlideIn.hide

  const mobileTextAlignClass =
    blockContent.textAlignMobile === "end"
      ? "text-right"
      : blockContent.textAlignMobile === "start"
      ? "text-left"
      : "text-center"

  const textAlignClass =
    blockContent.textAlign === "start"
      ? `sm:text-left ${mobileTextAlignClass}`
      : blockContent.textAlign === "center"
      ? `sm:text-center ${mobileTextAlignClass}`
      : `sm:text-right ${mobileTextAlignClass}`

  return (
    <>
      {/* //DESKTOP VERSION */}
      <div
        ref={animRef}
        className={` flex-col  flex md:px-20 sm:px-10 px-6  ${
          blockContent.textAlign === "start"
            ? "items-start"
            : blockContent.textAlign === "center"
            ? "items-center"
            : "items-end"
        }`}
        style={{
          marginLeft: blockContent.textAlign === "start" ? 0 : "auto",
          marginRight: blockContent.textAlign === "end" ? 0 : "auto",
          ...fadeSlideIn.transition,
          ...textAnimation,
        }}
      >
        <TitleGroup
          blockContent={blockContent}
          textAlignClass={textAlignClass}
        />
        <TextBasic
          small={blockContent.smallText}
          className={`${textAlignClass} pt-8`}
          innerHtml={blockContent.text}
        ></TextBasic>
        {blockContent.buttons &&
          blockContent.buttons.map((button: any, index: number) => {
            return (
              <Button
                link={button.buttonLink}
                color1={cssVariables.colors[button.buttonColor1]}
                color2={cssVariables.colors[button.buttonColor2]}
                flip={blockContent.textAlign === "end"}
                center={blockContent.textAlign === "center"}
              ></Button>
            )
          })}
      </div>
    </>
  )
}

export const basicTextBoxBlock_inputs = {
  key: "basicTextBlock",
  name: "Text Block",
  description: "A text block with title, subtitle and optional CTA",
  tags: ["Title", "Text"],
  inputs: [
    { inputGroupName: "titleInputGroup", type: "group" },

    {
      inputType: "richText",
      label: "Text",
      keyName: "text",
    },
    {
      inputType: "checkbox",
      label: "Smaller text",
      keyName: "smallText",
    },
    {
      inputType: "radio",
      labelArr: ["Left", "Center", "Right"],
      valueArr: ["start", "center", "end"],
      label: "Text align",
      keyName: "textAlign",
      defaultVal: "center",
    },
    {
      inputType: "radio",
      labelArr: ["Left", "Center", "Right"],
      valueArr: ["start", "center", "end"],
      label: "Text align mobile",
      keyName: "textAlignMobile",
      defaultVal: "center",
    },
    { type: "group", inputGroupName: "ctaInputGroup" },
  ],
  defaults: {
    ...BLOCKOPTIONS_DEFAULTS,
  },
}
