import React, { ChangeEvent } from "react"
import TextBasic from "./TextBasic"

type InputProps = {
  label: string
  name: string
  value: string
  onChange: (event: ChangeEvent<HTMLInputElement>) => void
  required?: boolean
  type?: "email" | "phone"
}

export function TextInput({
  label,
  name,
  onChange,
  required,
  value,
  type,
}: InputProps) {
  return (
    <div className="sm:flex mt-4 items-center">
      <div className="w-[270px] inline-flex">
        <TextBasic small>{label}</TextBasic>
        {required && (
          <TextBasic small color="#E9900C">
            *
          </TextBasic>
        )}
      </div>
      <input
        className="w-full border-slate-300 border rounded-md h-10 font-workSans pl-2"
        type={type ? type : "text"}
        name={name}
        value={value}
        onChange={onChange}
        required={required}
      ></input>
    </div>
  )
}

type TextAreaInputProps = {
  placeholder: string
  name: string
  value: string
  onChange: (event: ChangeEvent<HTMLTextAreaElement>) => void
}

export function TextAreaInput({
  placeholder,
  name,
  value,
  onChange,
}: TextAreaInputProps) {
  return (
    <textarea
      placeholder={placeholder}
      className="w-full border-slate-300 border rounded-md mt-4  font-workSans p-2 resize-none"
      rows={3}
      name={name}
      onChange={onChange}
      value={value}
    ></textarea>
  )
}
