import { useState, useEffect, useRef, useCallback } from "react"

export const useIntersection = () => {
  const [yFactor, setYFactor] = useState<number>(0)
  const containerRef = useRef<HTMLElement | null>(null)
  const waitingForAnimation = useRef(false)

  const ref = useCallback((node: HTMLElement) => {
    if (node !== null && typeof window !== "undefined") {
      containerRef.current = node
      handleScroll()
    }
  }, [])

  function handleScroll() {
    if (!waitingForAnimation.current && containerRef.current) {
      waitingForAnimation.current = true
      requestAnimationFrame(() => {
        const previewW =
          document.querySelector<HTMLIFrameElement>("#previewWindow")
        if (containerRef && containerRef.current) {
          if (previewW && previewW.contentWindow) {
            setYFactor(
              containerRef.current.getBoundingClientRect().bottom /
                (previewW.contentWindow.innerHeight +
                  containerRef.current.getBoundingClientRect().height)
            )
          } else {
            setYFactor(
              containerRef.current.getBoundingClientRect().bottom /
                (window.innerHeight +
                  containerRef.current.getBoundingClientRect().height)
            )
          }
          waitingForAnimation.current = false
        }
      })
    }
  }

  useEffect(() => {
    if (typeof window !== "undefined") {
      const previewW =
        document.querySelector<HTMLIFrameElement>("#previewWindow")
      if (
        previewW &&
        previewW.contentWindow &&
        previewW.contentWindow.document
      ) {
        const layoutElement =
          previewW.contentWindow.document.querySelector("#layout")
        if (layoutElement) {
          layoutElement.addEventListener("scroll", handleScroll)
        }
      }

      window.addEventListener("scroll", handleScroll)
    }
    return () => {
      window.removeEventListener("scroll", handleScroll)
      const previewW =
        document.querySelector<HTMLIFrameElement>("#previewWindow")
      if (
        previewW &&
        previewW.contentWindow &&
        previewW.contentWindow.document
      ) {
        const layoutElement =
          previewW.contentWindow.document.querySelector("#layout")
        if (layoutElement) {
          layoutElement.removeEventListener("scroll", handleScroll)
        }
      }
    }
  }, [])

  return [ref, yFactor] as [(e: HTMLElement) => void, number]
}

export default useIntersection
