import React, { ReactNode } from "react"
import useStore from "../../store"

type OverlayProps = {
  children: ReactNode
}

export default function PopupOverlay({ children }: OverlayProps) {
  const setPopupMsg = useStore(state => state.setPopupMsg)
  return (
    <div
      onClick={() => setPopupMsg(null)}
      className="w-screen h-screen fixed z-[9999] top-0 left-0 flex justify-center items-center bg-black bg-opacity-40"
    >
      <div className="shadow-md" onClick={e => e.stopPropagation()}>
        {children}
      </div>
    </div>
  )
}
