import React, { useContext } from "react"
import { isNumberObject } from "util/types"
import createFancyPriceStringsFromPriceWithVat from "../../../functions/createFancyPriceStringsFromPriceWithVat"
import TextBasic from "../../atoms/TextBasic"
import { ShopContext } from "../ShopModuleBlock"
import calculateTotalPrice from "./caculateTotalPrice"
import ShopButton from "./ShopButton"

type Props = {
  priceTagX: number
  className?: string
}

export default function PriceTagBox({ className, priceTagX }: Props) {
  const { setIsShowingOrderConfirm, totalPrice } = useContext(ShopContext)

  return (
    <div
      className={`flex fixed bottom-0 items-center  md:px-8 px-3 pt-3 flex-wrap justify-center  bg-[#f1f1f1] rounded-t-lg z-50 ${className}`}
      style={{
        left: `${priceTagX}px`,
        maxWidth: `max(calc(100vw - ${2 * priceTagX}px), 80vw)`,
      }}
    >
      <div className="mr-4">
        <div className="flex items-end ">
          <TextBasic className="font-semibold">
            €{createFancyPriceStringsFromPriceWithVat(totalPrice).exVat}
          </TextBasic>
          <TextBasic className="block ml-2" sizeClass="font-xs">
            per set (ex. VAT)
          </TextBasic>
        </div>
        <div className="flex items-end ">
          <TextBasic className="" small>
            €{createFancyPriceStringsFromPriceWithVat(totalPrice).inclVat}
          </TextBasic>
          <TextBasic className="block ml-2" sizeClass="font-xs">
            per set (incl. VAT)
          </TextBasic>
        </div>
      </div>

      <ShopButton
        bgColor="#0066FF"
        textColor="#FFFFFF"
        onClick={() => {
          setIsShowingOrderConfirm(true)
        }}
        text="Order"
        className="w-[100px]"
      />
    </div>
  )
}
