import React from "react"
import TextBasic from "../../atoms/TextBasic"

type TypeExplanationProps = {
  settypeIndex: Function
  typeInfo: { [x: string]: any }
  selected: boolean
  index: number
}

export default function TypeExplanationBox({
  settypeIndex,
  typeInfo,
  selected,
  index,
}: TypeExplanationProps) {
  return (
    <div
      className={`w-full mt-4 px-6 py-6 relative border-2 transition-all duration-300 rounded-md fadeIn ${
        selected
          ? "border-[#0066FF] border-inset"
          : "border-slate-300 cursor-pointer opacity-75"
      }`}
      onClick={() => {
        if (!selected) settypeIndex(index)
      }}
    >
      <div className="flex items-center">
        <TextBasic className="font-semibold">{typeInfo.name}</TextBasic>

        <TextBasic small color="#E9900C" className="ml-2">
          {typeInfo.isNew ? "New" : ""}
        </TextBasic>
      </div>
      <TextBasic small innerHtml={typeInfo.explanationText}></TextBasic>
      <div className="absolute top-2 right-6"></div>
    </div>
  )
}
