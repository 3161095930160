import React, { useContext } from "react"
import Button from "../../atoms/Button"
import H2 from "../../atoms/H2"
import H3 from "../../atoms/H3"
import TextBasic from "../../atoms/TextBasic"
import { DataContext } from "../../layout"
import ResponsiveImg from "../../molecules/ResponsiveImg"
import ShopButton from "./ShopButton"
import SpeakerImage from "./SpeakerImage"

type VerticalCategoryProps = {
  name: string
  image: string
  buttonText: string
  isNew: boolean
  explanationText: string
  onButtonClick: Function
  animationDelay: number
}

export default function CategoryVertical({
  name,
  image,
  buttonText,
  isNew,
  explanationText,
  onButtonClick,
  animationDelay,
}: VerticalCategoryProps) {
  const { cssVariables } = useContext(DataContext)
  return (
    <div
      className="w-60 flex flex-col items-center lg:mx-8 mx-6 xl:mx-12 fadeIn mb-16 md:mb-0"
      style={{ animationDelay: `${animationDelay}s` }}
    >
      <SpeakerImage
        image={image}
        animated
        animationDelay={animationDelay}
      ></SpeakerImage>

      <TextBasic small color="#E9900C" className="h-3 mt-4">
        {isNew ? "New" : ""}
      </TextBasic>
      <H3 className="mt-6 font-semibold" color={cssVariables.colors[0]}>
        {name}
      </H3>
      <ShopButton onClick={onButtonClick} text={buttonText} />
      <TextBasic
        small
        className="text-center mt-2"
        innerHtml={explanationText}
      ></TextBasic>
    </div>
  )
}
