import { useState, useEffect, useCallback, useRef } from "react"

export const useIsInScreen = (rootMargin: string) => {
  const [isVisible, setIsVisible] = useState(false)
  const containerRef = useRef<HTMLElement | null>(null)
  const observer = useRef<IntersectionObserver | null>(null)

  const ref = useCallback((node: HTMLElement) => {
    if (node !== null && typeof window !== "undefined") {
      containerRef.current = node
    }
  }, [])

  useEffect(() => {
    if (typeof window !== "undefined") {
      observer.current = new IntersectionObserver(
        ([entry]) => {
          setIsVisible(entry.isIntersecting)
        },
        { rootMargin }
      )

      containerRef.current && observer.current.observe(containerRef.current)
    }
    return () => {
      if (
        typeof window !== "undefined" &&
        containerRef.current &&
        observer.current
      )
        observer.current.unobserve(containerRef.current)
    }
  }, [ref])

  return [ref, isVisible]
}

export default useIsInScreen
