import React, { useContext, useEffect, useRef, useState } from "react"
import useSafeWindowEventlistener from "../../../hooks/useSafeWindowEventlistener"
import Button from "../../atoms/Button"
import H2 from "../../atoms/H2"
import H3 from "../../atoms/H3"
import TextBasic from "../../atoms/TextBasic"
import { DataContext } from "../../layout"
import ResponsiveImg from "../../molecules/ResponsiveImg"
import { ShopContext } from "../ShopModuleBlock"
import PriceTagBox from "./PriceTagBox"
import ShopButton from "./ShopButton"
import ShopNavigationBar from "./ShopNavigationBar"
import SpeakerImage from "./SpeakerImage"
import TypeExplanationBox from "./TypeExplanationBox"
import UpgradeBox from "./UpgradeBox"

type TypeSelectorModuleProps = {}

export default function TypeSelectorModule({}: TypeSelectorModuleProps) {
  const {
    typeIndex,
    setTypeIndex,
    setIsShowingOrderConfirm,
    blockContent,
    categoryIndex,
    variantIndex,
  } = useContext(ShopContext)
  const alignRef = useRef<HTMLDivElement>(null)
  const [priceTagX, setPriceTagX] = useState(0)
  const types =
    blockContent.mainCategories[categoryIndex].variants[variantIndex].types

  function getScreenCordinates(obj: any) {
    const p: any = {}
    p.x = obj.offsetLeft
    while (obj.offsetParent) {
      p.x = p.x + obj.offsetParent.offsetLeft
      if (obj == document.getElementsByTagName("body")[0]) {
        break
      } else {
        obj = obj.offsetParent
      }
    }
    return p
  }

  useSafeWindowEventlistener("resize", () =>
    setPriceTagX(getScreenCordinates(alignRef.current).x)
  )

  useEffect(() => {
    if (alignRef.current) {
      setPriceTagX(getScreenCordinates(alignRef.current).x)
    }
    return () => {}
  }, [])

  return (
    <>
      <div className="flex flex-col-reverse md:flex-row md:mt-10 md:items-start items-center min-h-[80vh] pb-20 relative overflow-visible">
        <div className="h-8 absolute left-0 -top-20">
          <ShopNavigationBar />
        </div>
        <div className="flex fadeIn mt-8 md:mt-0 sticky top-[170px]">
          <div className="flex flex-col items-center mx-6 ">
            <SpeakerImage image={types[typeIndex].imageBack}></SpeakerImage>
            <TextBasic className="opacity-50 mt-6" small>
              back
            </TextBasic>
          </div>
          <div className="flex flex-col items-center mx-6">
            <SpeakerImage image={types[typeIndex].imageFront}></SpeakerImage>

            <TextBasic className="opacity-50 mt-6" small>
              front
            </TextBasic>
          </div>
        </div>
        <PriceTagBox priceTagX={priceTagX} className="flex md:hidden" />
        <div ref={alignRef} className="flex flex-col items-start md:ml-10 ">
          <H2>
            {
              blockContent.mainCategories[categoryIndex].variants[variantIndex]
                .title
            }
          </H2>
          {types.map((typeInfo: any, index: number) => {
            return (
              <TypeExplanationBox
                typeInfo={typeInfo}
                selected={index === typeIndex}
                index={index}
                settypeIndex={setTypeIndex}
              />
            )
          })}

          {types[typeIndex].upgrades?.map((upgrade: any, index: number) => (
            <UpgradeBox
              upgrade={upgrade}
              upgradeIndex={index}
              key={upgrade.title}
            />
          ))}
        </div>
      </div>
      <PriceTagBox priceTagX={priceTagX} className="hidden md:flex" />
    </>
  )
}
