import React, { useContext, useState, useRef } from "react"
import { getItemFromId, Link, Page } from "@rubendenbrok/bloqmatter-cms"
import { DataContext } from "../layout"
import H3 from "../atoms/H3"
import H2 from "../atoms/H2"
import TextInput from "../atoms/TextInput"
import GreenButton from "../atoms/GreenButton"
import TextBasic from "../atoms/TextBasic"
import BloqmatterLink from "../atoms/BloqmatterLink"

import fb from "../../img/icons/FbIcon.svg"
import twitter from "../../img/icons/TwitterIcon.svg"
import mail from "../../img/icons/MailIcon.svg"
import insta from "../../img/icons/InstaIcon.svg"
import useStore from "../../store"

export default function Footer() {
  const {
    thisPage,
    cssVariables,
    footerData,
    headerData,
    siteOverviewData,
    processedImageData,
  } = useContext(DataContext)
  const setIsShowingCookiePopup = useStore(
    state => state.setIsShowingCookiePopup
  )
  const [emailValue, setEmailValue] = useState("")
  const emailRef = useRef("")
  const [subscribeMsg, setSubscribeMsg] = useState({
    state: "",
    msg: "",
  })

  function handleEmailChange(e: React.ChangeEvent<HTMLInputElement>) {
    setSubscribeMsg({ msg: "", state: "" })
    setEmailValue(e.target.value)
    emailRef.current = e.target.value
  }

  async function onNewsletterSubmit() {
    const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/
    if (emailRef.current && regex.test(emailRef.current)) {
      const url = `/.netlify/functions/newsletterSubscribe?email=${emailRef.current}`

      try {
        setSubscribeMsg({
          state: "",
          msg: footerData.subscribeProcessingText,
        })
        const response = await fetch(url)
        if (response.status === 200) {
          setSubscribeMsg({
            state: "",
            msg: footerData.subscribeSuccessText,
          })
          setEmailValue("")
        } else {
          setSubscribeMsg({
            state: "",
            msg: footerData.subscribeErrorText,
          })
        }
      } catch (e) {
        console.warn(e)
        setSubscribeMsg({
          state: "",
          msg: footerData.subscribeErrorText,
        })
      }
    } else {
      setSubscribeMsg({
        state: "",
        msg: footerData.subscribeInvalidEmailText,
      })
    }
  }

  const logoImgObj = getItemFromId(
    siteOverviewData.logoImage,
    processedImageData
  )

  return (
    <footer
      className="py-10 flex flex-col items-center relative"
      style={{
        backgroundColor: cssVariables.colors[footerData.bgColor],
        color: cssVariables.colors[footerData.textColor],
      }}
    >
      <div className="flex lg:flex-row flex-col max-w-[100vw] justify-between items-center px-6 mb-6 mt-4">
        {footerData.links?.map((item: any, index: number) => (
          <BloqmatterLink link={item.link} key={index}>
            <FooterLinkItem link={item.link} />
          </BloqmatterLink>
        ))}
      </div>
      <div className="flex w-[300px] max-w-[75vw] justify-between items-center px-6 mb-20 ">
        {footerData.email && (
          <a
            className="block text-xl font-titleAccent"
            href={"mailto:" + footerData.email}
          >
            <img src={mail} alt="Email Icon" />
          </a>
        )}
        {footerData.twitter && (
          <a
            className="block text-xl font-titleAccent"
            href={footerData.twitter}
            target="_blank"
          >
            <img src={twitter} alt="Twitter Icon" />
          </a>
        )}
        {footerData.instagram && (
          <a
            className="block text-xl font-titleAccent"
            href={footerData.instagram}
            target="_blank"
          >
            <img src={insta} alt="Instagram Icon" />
          </a>
        )}
        {footerData.facebook && (
          <a
            className="block text-xl font-titleAccent"
            href={footerData.facebook}
            target="_blank"
          >
            <img src={fb} alt="Facebook Icon" />
          </a>
        )}
      </div>
      <H3 color={footerData.textColor} className="text-3xl text-center">
        {footerData.newsletterTitle}
      </H3>
      <H3
        color={footerData.textColor}
        className={`sm:text-lg text-md text-center`}
      >
        {footerData.newsletterSubtitle}
      </H3>
      <div
        className="inline-flex flex-wrap justify-center relative "
        id={"contact"}
      >
        <TextInput
          typeof="text"
          className="mt-4 "
          placeholder={
            footerData.emailPlaceholder
              ? footerData.emailPlaceholder
              : "Your email"
          }
          onChange={handleEmailChange}
          value={emailValue}
        ></TextInput>
        <GreenButton onClick={onNewsletterSubmit} className="ml-2 mt-4 ">
          {footerData.subscribeButtonText
            ? footerData.subscribeButtonText
            : "Join"}
        </GreenButton>

        <TextBasic small className={`block w-full text-center italic h-20`}>
          {subscribeMsg.msg}
        </TextBasic>
      </div>

      {logoImgObj && (
        <img
          src={logoImgObj.urls.fallback.s}
          className="invert"
          alt="KAD-audio logo"
        />
      )}
      <TextBasic className="mt-20 px-6 md:text-sm lg:text-sm xl:text-sm text-sm">
        {footerData.copyrightText}
      </TextBasic>
      <div
        className="cursor-pointer"
        onClick={() => {
          setIsShowingCookiePopup(true)
        }}
      >
        <TextBasic className="underline" sizeClass="text-xs">
          {footerData.cookieSettingsText}
        </TextBasic>
      </div>
      <TextBasic sizeClass="text-xs">
        <a
          href="./General-terms-and-conditions-KAD-Audio-2022.pdf"
          target="_blank"
          className="underline cursor-pointer"
        >
          {footerData.termsLinkText}
        </a>
      </TextBasic>
    </footer>
  )
}

function FooterLinkItem({ link }: { link: Link }) {
  const {
    thisPage,
    cssVariables,
    footerData,
    headerData,
    siteOverviewData,
    processedImageData,
  } = useContext(DataContext)
  return (
    <TextBasic
      className="mx-8  my-2 uppercase"
      small
      color={cssVariables.colors[footerData.textColor]}
    >
      {link.text}
    </TextBasic>
  )
}

export const footer_inputs = [
  {
    inputType: "text",
    keyName: "facebook",
    label: "Facebook link",
  },
  {
    inputType: "text",
    keyName: "instagram",
    label: "Instagram link",
  },
  {
    inputType: "text",
    keyName: "twitter",
    label: "Twitter link",
  },
  { inputType: "color", keyName: "textColor", label: "Text color:" },
  { inputType: "color", keyName: "bgColor", label: "Background color:" },
  {
    inputType: "expandableInputs",
    label: "Newsletter texts",
    childInputs: [
      {
        inputType: "text",
        keyName: "newsletterTitle",
        label: "Newsletter line 1",
      },
      {
        inputType: "text",
        keyName: "newsletterSubtitle",
        label: "Newsletter line 2",
      },
      {
        inputType: "text",
        keyName: "emailPlaceholder",
        label: "Email text field placeholder",
        defaultVal: "Your email",
      },
      {
        inputType: "text",
        keyName: "subscribeButtonText",
        label: "Subscribe button text",
        defaultVal: "Join",
      },
      {
        inputType: "text",
        keyName: "subscribeProcessingText",
        label: "Subscribe processing text",
        defaultVal: "Processing...",
      },
      {
        inputType: "text",
        keyName: "subscribeSuccessText",
        label: "Subscribe success text",
        defaultVal: "Thank you, you will hear from us soon!",
      },
      {
        inputType: "text",
        keyName: "subscribeInvalidEmailText",
        label: "Subscribe invalid email text",
        defaultVal: "Please enter a valid email adress",
      },
      {
        inputType: "text",
        keyName: "subscribeErrorText",
        label: "Subscribe error text",
        defaultVal: "Something went wrong, please try again later",
      },
    ],
  },

  {
    inputType: "inputArray",
    keyName: "links",
    label: "Footer links:",
    itemName: "Link",
    childNameKey: "link",
    childInputs: [{ inputType: "link", keyName: "link", label: "" }],
  },
  {
    inputType: "text",
    keyName: "termsLinkText",
    label: "Terms and Conditions link text",
  },
  {
    inputType: "text",
    keyName: "copyrightText",
    label: "Copyright text",
    defaultVal: "© 2023 KADAUDIO.COM . ALL RIGHTS RESERVED.",
  },
  {
    inputType: "text",
    keyName: "cookieSettingsText",
    label: "Cookie settings text",
  },
]
