import React from "react"

interface MenuIconProps extends React.HTMLAttributes<HTMLDivElement> {
  color?: string
}

export default function MenuIcon({ className, color, ...rest }: MenuIconProps) {
  return (
    <div
      className={`flex w-12 h-10 scale-[0.6]  flex-col justify-between p-2 transition-opacity ${className}`}
      style={{
        zIndex: 999,
      }}
      {...rest}
    >
      <div
        style={{ backgroundColor: color ? color : "black" }}
        className="w-full h-1 rounded-sm"
      ></div>
      <div
        style={{ backgroundColor: color ? color : "black" }}
        className="w-full h-1 rounded-sm"
      ></div>
      <div
        style={{ backgroundColor: color ? color : "black" }}
        className="w-full h-1 rounded-sm"
      ></div>
    </div>
  )
}
