import React, { useContext } from "react"
import { getItemFromId } from "@rubendenbrok/bloqmatter-cms"
import getCascadingColor from "../../functions/getCascadingColor"
import { getAbsolutePositionStyle } from "../../styles/util"
import H1 from "../atoms/H1"
import H3 from "../atoms/H3"
import { DEFAULT_BLOCKOPTIONS_DEFAULTS } from "@rubendenbrok/bloqmatter-cms"

import { DataContext } from "../layout"
import BgVideo from "../molecules/BgVideo"
import { BLOCKOPTIONS_DEFAULTS } from "../../custom_blockoptions_inputs"

/*
BESCHRIJVING:
Een Hero video die altijd de breedte van het scherm aanhoudt, met een eventuele tekst erover.

DATA:
{
  title: string
  subtitle: string
  text: html string
  textHorizontalPos: 'start' | 'center' | 'end'
  textVerticalPos: 'start' | 'center' | 'end'
  video: string (video ID)


}
*/

type props = any

export default function HeroBlock_Display({ blockContent }: props) {
  const {
    cssVariables,
    thisPage,
    siteOverviewData,
    videoData,
    processedImageData,
  } = useContext(DataContext)
  const videoObj = getItemFromId(blockContent.video, videoData)
  const ratio = videoObj?.metadata.height / videoObj?.metadata.width

  const textboxPositionStyle = getAbsolutePositionStyle(
    blockContent.textHorizontalPos,
    blockContent.textVerticalPos,
    "80px"
  )

  const textAlignClass =
    blockContent.textHorizontalPos === "start"
      ? "text-left"
      : blockContent.textHorizontalPos === "center"
      ? "text-center"
      : "text-right"

  const bgImg = getItemFromId(blockContent.bg_image, processedImageData)
  const bgImgAspectRatio = bgImg ? bgImg.originalW / bgImg.originalH : 0

  const height =
    bgImgAspectRatio && blockContent.neverCropBgImage
      ? `calc(100vw * ${bgImgAspectRatio})`
      : "100vh"

  return (
    <div
      className="w-full relative min-h-[500px]"
      style={{ height: height }}
      // style={{ paddingBottom: ratio * 100 + "%" }}
    >
      <div
        className={`absolute inline-block ${textAlignClass}   pb-48`}
        style={{ ...textboxPositionStyle }}
      >
        {blockContent.title && (
          <H1
            className={` ${textAlignClass} mb-4`}
            style={{
              color: thisPage
                ? getCascadingColor(
                    cssVariables,
                    siteOverviewData,
                    thisPage,
                    blockContent
                  ).title
                : "black",
            }}
          >
            {blockContent.title}
          </H1>
        )}
        {blockContent.subtitle && (
          <H1
            color={
              thisPage
                ? getCascadingColor(
                    cssVariables,
                    siteOverviewData,
                    thisPage,
                    blockContent
                  ).title
                : "black"
            }
            className={`text-${blockContent.textHorizontalPos} overflow-y-visible font-titleAccent`}
          >
            {blockContent.subtitle}
          </H1>
        )}
      </div>
    </div>
  )
}

export const HeroBlock_Inputs = {
  key: "heroBlock",
  name: "Hero Block",
  tags: ["Video", "Media", "Hero", "Title"],
  description:
    "A hero block that fills the screen, with an optional title overlayed on top of it.",
  inputs: [
    {
      inputType: "expandableInputs",
      label: "Text",
      childInputs: [
        {
          inputType: "text",
          label: "Title",
          keyName: "title",
          defaultVal: "",
        },
        {
          inputType: "text",
          label: "Subtitle",
          keyName: "subtitle",
          defaultVal: "",
        },
        {
          inputType: "radio",
          labelArr: ["Left", "Center", "Right"],
          valueArr: ["start", "center", "end"],
          label: "Title Horizontal Position",
          keyName: "textHorizontalPos",
          defaultVal: "left",
        },
        {
          inputType: "radio",
          labelArr: ["Top", "Center", "Bottom"],
          valueArr: ["start", "center", "end"],
          label: "Title Vertical Position",
          keyName: "textVerticalPos",
          defaultVal: "center",
        },
      ],
    },
    {
      inputType: "checkbox",
      label: "Never crop background image",
      keyName: "neverCropBgImage",
      defaultVal: false,
      hoverHelp:
        "If you check this, when using a background image this block will scale its height to always display the full image, instead of cropping it to always be the height of the screen.",
    },
  ],
  defaults: {
    ...BLOCKOPTIONS_DEFAULTS,
  },
}
