import React, { ReactNode, useContext, useState } from "react"

import { Link } from "@rubendenbrok/bloqmatter-cms"
import TextBasic from "../../atoms/TextBasic"
import { DataContext } from "../../layout"

type elementProps = {
  className?: string
  text: string
  flip?: boolean
  center?: boolean
  textColor?: string
  bgColor?: string
  onClick?: Function
}

export default function ShopButton({
  className,
  text,
  onClick,
  flip,
  center,
  bgColor,
  textColor,
}: elementProps) {
  const [hover, setHover] = useState(false)
  const arrowSize = 14
  const { cssVariables } = useContext(DataContext)
  return (
    <div
      onClick={() => onClick()}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      style={{
        backgroundColor: bgColor ? bgColor : cssVariables.colors[3],
      }}
      className={`my-3 relative inline-block py-3 px-5 w-full rounded-md overflow-hidden transition-colors hover:cursor-pointer  ${className}`}
    >
      <TextBasic
        small
        className="font-titleAccent text-center transition-colors"
        color={textColor ? textColor : cssVariables.colors[0]}
      >
        {text}
      </TextBasic>
      <div
        className={`absolute w-full h-full inset-0 bg-black ${
          hover ? "opacity-25" : "opacity-0"
        } transition-opacity`}
      ></div>
    </div>
  )
}

export function ShopButtonSubmit({
  className,
  text,
  onClick,
  flip,
  center,
  bgColor,
  textColor,
}: elementProps) {
  const [hover, setHover] = useState(false)
  const arrowSize = 14
  const { cssVariables } = useContext(DataContext)
  return (
    <div className={`relative my-3 ${className}`}>
      <div
        className={`absolute w-full h-full inset-0 z-10 bg-black pointer-events-none ${
          hover ? "opacity-25" : "opacity-0"
        } transition-opacity`}
      ></div>
      <input
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        type="submit"
        style={{
          backgroundColor: bgColor ? bgColor : cssVariables.colors[3],
          color: textColor ? textColor : cssVariables.colors[0],
        }}
        className={`relative inline-block py-3 px-5 w-full rounded-md overflow-hidden transition-colors hover:cursor-pointer font-titleAccent text-center`}
        value={text}
      ></input>
    </div>
  )
}
