import React, { ReactNode } from "react"

type elementProps = {
  className?: string
  color?: string
  children?: ReactNode
  small?: boolean
  setInnerHtml?: string
}

export default function H1({
  className,
  color,
  children,
  small,
  setInnerHtml,
}: elementProps) {
  return (
    <h1
      style={{ color: color ? color : "inherit" }}
      className={`font-workSans ${
        small
          ? "lg:text-5xl sm:text-3xl text-2xl"
          : "lg:text-7xl sm:text-5xl text-3xl"
      }  font-semibold m-0 -mt-4 select-none  ${className}`}
      //dangerouslySetInnerHTML={setInnerHtml ? { __html: setInnerHtml } : null}
    >
      {children}
    </h1>
  )
}
