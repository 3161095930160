/*
BESCHRIJVING:
Een block dat een video of afbeelding laat zien naast een tekst met titel en subtitel. 
De gebruiker kan kiezen of de media op mobile ook te zien moet zijn, als dat zo is komt die boven de tekst te staan.

De media bewaart altijd zijn oorspronkelijke aspect ratio, wordt niet gecropt, en wordt nooit groter dan zijn oorspronkelijke formaat.

DATA:
{
  title: string
  subtitle: string
  text: html string
  textAlign: 'start' | 'center' | 'end'
  mediaFormat: 'video' | 'image'
  video: string (video ID)
  image: string (image ID)
  switchOrder: boolean (Media verschijnt rechts ipv links van tekst if true)
  showMediaOnMobile: boolean
  buttons: CTA Input group - default: {buttonlink: Link, buttonColor1: color, buttonColor2: color}

}
*/

import React, { useContext } from "react"
import { getItemFromId } from "@rubendenbrok/bloqmatter-cms"
import getCascadingColor from "../../functions/getCascadingColor"
import H3 from "../atoms/H3"
import H1 from "../atoms/H1"
import TextBasic from "../atoms/TextBasic"
import { paddingFactor } from "./DisplayBlock_Container"
import { DataContext } from "../layout"
import BgVideo from "../molecules/BgVideo"
import Button from "../atoms/Button"
import ResponsiveImg from "../molecules/ResponsiveImg"
import useIsInScreen from "../../hooks/useIsInScreen"
import { fadeSlideIn } from "../../styles/animations"
import { DEFAULT_BLOCKOPTIONS_DEFAULTS } from "@rubendenbrok/bloqmatter-cms"
import TitleGroup from "../atoms/TitleGroup"
import { BLOCKOPTIONS_DEFAULTS } from "../../custom_blockoptions_inputs"

type props = any

export default function MediaTextTwoColorsBlock_Display({
  blockContent,
}: props) {
  const {
    cssVariables,
    thisPage,
    siteOverviewData,
    videoData,
    processedImageData,
  } = useContext(DataContext)
  const videoObj = getItemFromId(blockContent.video, videoData)
  const imgObj = getItemFromId(blockContent.image, processedImageData)

  const [animRef, isVisible] = useIsInScreen("100px")
  const scrollAnimation = isVisible ? fadeSlideIn.show : fadeSlideIn.hide

  const MIN_MEDIA_WIDTH = "340px"
  const MEDIA_ASPECT_RATIO =
    blockContent.mediaFormat === "image"
      ? imgObj?.originalH / imgObj?.originalW
      : videoObj?.metadata.height / videoObj?.metadata.width
  const MEDIA_MAX_WIDTH =
    blockContent.mediaFormat === "image"
      ? imgObj?.originalW
      : videoObj?.metadata.width
  const MEDIA_MAX_HEIGHT =
    blockContent.mediaFormat === "image"
      ? imgObj?.originalH
      : videoObj?.metadata.height

  const mobileTextAlignClass =
    blockContent.textAlignMobile === "end"
      ? "text-right"
      : blockContent.textAlignMobile === "start"
      ? "text-left"
      : "text-center"

  const textAlignClass =
    blockContent.textAlign === "start"
      ? `sm:text-left ${mobileTextAlignClass}`
      : blockContent.textAlign === "center"
      ? `sm:text-center ${mobileTextAlignClass}`
      : `sm:text-right ${mobileTextAlignClass}`

  const ACCENT_AREA_HEIGHTCLASS = "h-20 sm:h-24 lg:h-36"
  return (
    <div
      className={`w-full relative flex flex-col justify-around items-start ${
        blockContent.switchOrder ? "lg:flex-row-reverse" : "lg:flex-row"
      }`}
    >
      <div
        className={`w-[15000px] absolute  -left-[5000px] `}
        style={{
          backgroundColor: cssVariables.colors[blockContent.upperBgColor],
          top: blockContent.spacing_paddingTop * -paddingFactor - 1 + "px",
        }}
      >
        <div
          className={`${ACCENT_AREA_HEIGHTCLASS}`}
          style={{
            marginTop: blockContent.spacing_paddingTop * paddingFactor + "px",
          }}
        ></div>
      </div>
      <div
        className={`relative flex-shrink-0 -mt-10 ${
          blockContent.showMediaOnMobile ? "block" : "hidden lg:block"
        } ${
          blockContent.mediaFormat === "image"
            ? blockContent.switchOrder
              ? "  px-6 mx-auto"
              : "  px-6 mx-auto"
            : ""
        }`}
        style={{
          width: `min(max(40vw, ${MIN_MEDIA_WIDTH}), ${MEDIA_MAX_WIDTH}px)`,
          height: `min(${MEDIA_MAX_HEIGHT}px, calc(${MEDIA_ASPECT_RATIO} * max(50vw, ${MIN_MEDIA_WIDTH})), 600px)`,
          maxHeight: MEDIA_MAX_HEIGHT + "px",
          maxWidth: ``,
        }}
      >
        {blockContent.mediaFormat === "image" ? (
          <ResponsiveImg image={blockContent.image} imageCrop={false} />
        ) : (
          <BgVideo videoId={blockContent.video} />
        )}
      </div>

      <div
        ref={animRef}
        className={`block w-full lg:w-1/2  ${textAlignClass}  px-6 lg:px-12 ${
          blockContent.showMediaOnMobile ? "lg:mt-0 mt-0" : "mt-2"
        }`}
        style={{
          ...fadeSlideIn.transition,
          ...scrollAnimation,
        }}
      >
        <div className={`${ACCENT_AREA_HEIGHTCLASS}`}>
          <TitleGroup
            blockContent={blockContent}
            textAlignClass={textAlignClass}
          />
        </div>
        {blockContent.text && (
          <TextBasic
            color={
              thisPage
                ? getCascadingColor(
                    cssVariables,
                    siteOverviewData,
                    thisPage,
                    blockContent
                  ).text
                : "black"
            }
            className={`mt-10 ${textAlignClass}`}
            innerHtml={blockContent.text}
            small={blockContent.smallText}
          ></TextBasic>
        )}
        {blockContent.buttons &&
          blockContent.buttons.map((button: any, index: number) => {
            return (
              <Button
                key={index}
                link={button.buttonLink}
                color1={cssVariables.colors[button.buttonColor1]}
                color2={cssVariables.colors[button.buttonColor2]}
                flip={blockContent.textAlign === "end"}
              ></Button>
            )
          })}
      </div>
    </div>
  )
}

export const MediaTextTwoColorsBlock_Inputs = {
  key: "mediaTextTwoColorsBlock",
  name: "Two-colored Media / Text Block ",
  tags: ["Text", "Media", "Image", "Video"],
  description:
    "A block of text with title and optional CTA next to an image or video. Titles can have a different background color than the text",
  inputs: [
    {
      inputType: "expandableInputs",
      label: "Text",
      childInputs: [
        { inputGroupName: "titleInputGroup", type: "group" },
        {
          inputType: "richText",
          label: "Text",
          keyName: "text",
          defaultVal: "",
        },
        {
          inputType: "radio",
          labelArr: ["Left", "Center", "Right"],
          valueArr: ["start", "center", "end"],
          label: "Text align",
          keyName: "textAlign",
          defaultVal: "center",
        },
        {
          inputType: "checkbox",
          label: "Smaller text",
          keyName: "smallText",
        },
        {
          inputType: "radio",
          labelArr: ["Left", "Center", "Right"],
          valueArr: ["start", "center", "end"],
          label: "Text align mobile",
          keyName: "textAlignMobile",
          defaultVal: "center",
        },
      ],
    },
    {
      inputType: "expandableInputs",
      label: "Media",
      childInputs: [
        {
          inputType: "conditionalRadio",
          label: "Media format",
          keyName: "mediaFormat",
          labelArr: ["Video", "Image"],
          valueArr: ["video", "image"],
          childInputsArr: [
            [
              {
                inputType: "video",
                label: "Video",
                keyName: "video",
                defaultVal: "",
              },
            ],
            [
              {
                inputType: "image",
                label: "Image",
                keyName: "image",
                defaultVal: "",
              },
            ],
          ],
        },
      ],
    },
    {
      inputType: "checkbox",
      label: "Media right of text",
      keyName: "switchOrder",
    },
    {
      inputType: "checkbox",
      label: "Show media on mobile",
      keyName: "showMediaOnMobile",
    },
    {
      inputType: "color",
      label: "Upper background color",
      keyName: "upperBgColor",
    },
    { type: "group", inputGroupName: "ctaInputGroup" },
  ],
  defaults: {
    ...BLOCKOPTIONS_DEFAULTS,
  },
}
