export default function calculateTotalPrice(
  blockContent: any,
  categoryIndex: number | null,
  variantIndex: number | null,
  typeIndex: number | null,
  selectedOptions: (number | null)[]
) {
  if (
    typeof categoryIndex === "number" &&
    typeof variantIndex === "number" &&
    typeof typeIndex === "number"
  ) {
    const basePrice = parseFloat(
      blockContent.mainCategories[categoryIndex].variants[variantIndex].types[
        typeIndex
      ].price
    )
    let addedOptionsPrice = 0

    selectedOptions.forEach(
      (selectedOptionIndex: number | null, upgradeIndex: number) => {
        if (
          typeof selectedOptionIndex === "number" &&
          blockContent.mainCategories[categoryIndex].variants[variantIndex]
            .types[typeIndex].upgrades?.[upgradeIndex]?.options?.[
            selectedOptionIndex
          ] &&
          blockContent.mainCategories[categoryIndex].variants[variantIndex]
            .types[typeIndex].upgrades[upgradeIndex].options[
            selectedOptionIndex
          ].price
        ) {
          addedOptionsPrice += parseFloat(
            blockContent.mainCategories[categoryIndex].variants[variantIndex]
              .types[typeIndex].upgrades[upgradeIndex].options[
              selectedOptionIndex
            ].price
          )
        }
      }
    )

    return basePrice + addedOptionsPrice
  } else {
    return 0
  }
}
