import { getItemFromId } from "@rubendenbrok/bloqmatter-cms"
import React, { useContext, useState, useRef } from "react"
import useSafeWindowEventlistener from "../../hooks/useSafeWindowEventlistener"
import useScrollAnimation from "../../hooks/useScrollAnimation"
import { DataContext } from "../layout"

type DecorationTriangleProps = {
  blockContent: { [x: string]: any }
  sectionHeight: number
  sectionWidth: number
  sectionPaddingTop: number
  sectionPaddingBottom: number
  blockIndex: number
}

export default function DecorationTriangle({
  blockContent,
  sectionHeight,
  sectionWidth,
  sectionPaddingTop,
  sectionPaddingBottom,
  blockIndex,
}: DecorationTriangleProps) {
  const { cssVariables, processedImageData } = useContext(DataContext)

  const [animRef, yFactor] = useScrollAnimation({
    scaleExponent: 0.3,
    scrollHeight:
      typeof window !== "undefined" ? window?.innerHeight * 1.2 : 1000,
    offset: -200,
    disabled: !blockContent.decorationTriangle_scrollAnimation,
  })

  // shape info per decoration triangle shape option
  const decorationTriangleShapes = {
    left: {
      position: "top",
      viewBox: "0 0 5 10",
      d: "M 4 4 L 0 0 L 0 10 L 4 6 Q 4.502 5.495 4.522 5.002 Q 4.512 4.498 4 4",
      className: "top-0 left-0",
    },
    right: {
      position: "top",
      viewBox: "0 0 8 9",
      d: "M 8 0 L 1.183 0 q -0.885 -0.021 -0.973 0.83 Q 0.221 1.327 0.58 1.631 L 8 9",
      className: "top-0 right-0",
    },
    bottom: {
      position: "bottom",
      viewBox: "0 -6.5 10 6.5",
      d: "M 10 0 L 1.183 0 q -0.885 0.021 -0.973 -0.83 Q 0.221 -1.327 0.58 -1.631 L 5 -6 Q 5.5 -6.5 6 -6.5 Q 6.5 -6.5 7 -6 L 10 -3 L 10 0",
      className: "right-0",
    },
  }

  const decorationTriangleOptions = {
    isShown: blockContent.decorationTriangle_isShown,
    shape:
      decorationTriangleShapes[
        blockContent.decorationTriangle_shape as keyof typeof decorationTriangleShapes
      ],
    backgroundColor: blockContent.decorationTriangle_backgroundColor,
    backgroundImage: blockContent.decorationTriangle_backgroundImage
      ? getItemFromId(
          blockContent.decorationTriangle_backgroundImage,
          processedImageData
        )
      : null,
    opacity: blockContent.decorationTriangle_opacity,
    size: blockContent.decorationTriangle_size / 100,
    animation: blockContent.decorationTriangle_animation,
  }

  const processedSectionHeight =
    blockContent.decorationTriangle_shape === "left"
      ? sectionHeight - sectionPaddingBottom - sectionPaddingTop
      : blockContent.decorationTriangle_shape === "right"
      ? sectionHeight - sectionPaddingBottom
      : blockContent.decorationTriangle_shape === "bottom"
      ? sectionHeight - sectionPaddingTop - sectionPaddingBottom
      : sectionHeight

  /**
   * - base the height of the triangle on the diagonal of the section.
   * - divide that by the aspect ratio of the section (as to contain the size
   *   for very wide sections)
   * - multiply that by size factor from decorationTriangleOptions
   */

  const sectionDiagonal = Math.sqrt(
    Math.pow(processedSectionHeight, 2) + Math.pow(sectionWidth, 2)
  )
  const sectionAspectRatio = sectionWidth / processedSectionHeight
  const height =
    sectionDiagonal *
    Math.min(1, 1 / (sectionAspectRatio * 0.5)) *
    decorationTriangleOptions.size

  // if the triagle is left, calculate an offset to keep it centered on content no matter the padding
  const offsetY =
    blockContent.decorationTriangle_shape === "left"
      ? processedSectionHeight / 2 - height / 2 + sectionPaddingTop
      : 0

  const translateXAnimation =
    blockContent.decorationTriangle_shape === "left" ? -300 : 300

  return (
    <div
      ref={animRef}
      className={`absolute pointer-events-none ${decorationTriangleOptions.shape?.className} `}
      style={{
        opacity: Math.min(
          (decorationTriangleOptions.opacity / 50) * yFactor,
          1
        ),
        height: `${height}px`,
        marginTop: `${offsetY}px`,
        transform: `translateX(${(1 - yFactor) * translateXAnimation}px)`,
        bottom:
          blockContent.decorationTriangle_shape === "bottom"
            ? sectionPaddingBottom + "px"
            : "",
      }}
    >
      <svg
        className={`h-full `}
        viewBox={decorationTriangleOptions.shape?.viewBox}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        {decorationTriangleOptions.backgroundImage && (
          <defs>
            <pattern
              id={`img-${decorationTriangleOptions.backgroundImage.id}-${blockIndex}`}
              width="100%"
              height="100%"
              patternUnits="userSpaceOnUse"
              x="0"
              y="0"
            >
              <image
                href={decorationTriangleOptions.backgroundImage.urls.fallback.l} // which size to use?
                x="0"
                y="0"
                width="100%"
                height="100%"
                preserveAspectRatio="xMidYMid slice"
              />
            </pattern>
          </defs>
        )}
        <path
          d={decorationTriangleOptions.shape?.d}
          fill={
            decorationTriangleOptions.backgroundImage
              ? `url(#img-${decorationTriangleOptions.backgroundImage.id}-${blockIndex})`
              : cssVariables.colors[decorationTriangleOptions.backgroundColor]
          }
        />
      </svg>
    </div>
  )
}

export const decorationTriangleInputs = {
  tag: "Decoration Triangle",
  options: [
    {
      inputType: "conditional",
      label: "Show decoration triangle",
      keyName: "decorationTriangle_isShown",
      childInputs: [
        {
          inputType: "radio",
          label: "Shape",
          keyName: "decorationTriangle_shape",
          labelArr: ["right", "left", "bottom"],
          valueArr: ["right", "left", "bottom"],
          defaultVal: "right",
        },
        {
          inputType: "color",
          label: "Background color",
          keyName: "decorationTriangle_backgroundColor",
          defaultVal: 0,
        },
        {
          inputType: "image",
          label: "Background image",
          keyName: "decorationTriangle_backgroundImage",
          defaultVal: "",
        },
        {
          inputType: "slider",
          label: "Opacity",
          keyName: "decorationTriangle_opacity",
          min: 0,
          max: 100,
          defaultVal: 100,
        },
        {
          inputType: "slider",
          label: "Size",
          keyName: "decorationTriangle_size",
          min: 0,
          max: 200,
          //defaultVal: 100,
        },
        {
          inputType: "checkbox",
          label: "Scroll animation",
          keyName: "decorationTriangle_scrollAnimation",
        },
      ],
    },
  ],
}

export const decorationTriangleDefaults = {
  decorationTriangle_isShown: false,
  decorationTriangle_shape: "right",
  decorationTriangle_backgroundColor: 0,
  decorationTriangle_backgroundImage: "",
  decorationTriangle_opacity: 100,
  decorationTriangle_size: 100,
  decorationTriangle_animation: "none",
}
