import React, {
  ChangeEvent,
  useContext,
  useReducer,
  useRef,
  useState,
} from "react"

import Button from "../atoms/Button"
import H2 from "../atoms/H2"
import TextBasic from "../atoms/TextBasic"
import { DataContext } from "../layout"
import H3 from "../atoms/H3"
import { BLOCKOPTIONS_DEFAULTS } from "../../custom_blockoptions_inputs"
import { TextInput, TextAreaInput } from "../atoms/FormInputs"
import { ShopButtonSubmit } from "./shopElements/ShopButton"
import H1 from "../atoms/H1"

type props = any

type StateType = {
  firstName: string
  lastName: string
  phone: string
  email: string
  preferredDate: string
  additionalInfo: string
}

export default function BookYourAppointmentBlock_Display({
  blockContent,
}: props) {
  const initState = {
    firstName: "",
    lastName: "",
    phone: "",
    email: "",
    preferredDate: "",
    additionalInfo: "",
  }
  const reducer = (state: StateType, update: Partial<StateType>) => {
    return { ...state, ...update }
  }
  const { cssVariables, thisPage, siteOverviewData } = useContext(DataContext)
  const [formState, dispatch] = useReducer(reducer, initState)
  const formContentRef = useRef(initState)
  const formRef = useRef<HTMLFormElement>(null)
  const [formEventState, setFormEventState] = useState<
    "form" | "processing" | "success" | "error"
  >("form")

  const onChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    dispatch({ [e.target.name]: e.target.value })
    formContentRef.current = {
      ...formContentRef.current,
      [e.target.name]: e.target.value,
    }
  }

  const handleSubmit = async (e: any) => {
    e.preventDefault()
    if (typeof window !== "undefined") {
      window.scrollTo({ left: 0, top: 0, behavior: "smooth" })
    }
    const data: { [x: string]: any } = { ...formContentRef.current }

    const url = `/.netlify/functions/sendDemoAppointmentMail?data=${JSON.stringify(
      data
    )}`

    try {
      setFormEventState("processing")
      const response = await fetch(url)
      if (response.status === 200) {
        setFormEventState("success")
      } else {
        console.log(response)
        setFormEventState("error")
      }
    } catch (e) {
      console.warn(e)
      setFormEventState("error")
    }
  }

  return (
    <>
      {/* //DESKTOP VERSION */}
      <div
        className={` flex-col  flex md:px-20 sm:px-10 px-6 pt-16  `}
        style={{}}
      >
        <H1 className="text-center">{blockContent.title}</H1>
        <H3 className="text-center font-semibold">{blockContent.subtitle}</H3>
        <div className="max-w-3xl mt-16 mx-auto">
          <div>
            {formEventState === "form" ? (
              <TextBasic
                small
                innerHtml={blockContent.introText}
                className="opacity-60"
              ></TextBasic>
            ) : formEventState === "processing" ? (
              <H3>{blockContent.processingText}</H3>
            ) : formEventState === "error" ? (
              <H3>{blockContent.errorText}</H3>
            ) : (
              <H3>{blockContent.successText}</H3>
            )}
          </div>
          <form ref={formRef} className="my-12" onSubmit={handleSubmit}>
            <H3 className="font-semibold">{blockContent.formTitle}</H3>
            <TextInput
              name="firstName"
              onChange={onChange}
              label={blockContent.labelFirstName}
              required
              value={formState.firstName}
            />
            <TextInput
              name="lastName"
              onChange={onChange}
              label={blockContent.labelLastName}
              required
              value={formState.lastName}
            />
            <TextInput
              name="phone"
              type="phone"
              onChange={onChange}
              label={blockContent.labelPhone}
              required
              value={formState.phone}
            />
            <TextInput
              name="email"
              type="email"
              onChange={onChange}
              label={blockContent.labelEmail}
              required
              value={formState.email}
            />
            <TextInput
              name="preferredDate"
              onChange={onChange}
              label={blockContent.labelPreferredDate}
              value={formState.preferredDate}
            />
            <H3 className="font-semibold mt-12">{blockContent.formTitle2}</H3>
            <TextAreaInput
              name="additionalInfo"
              placeholder={blockContent.placeholderAdditionalInfo}
              onChange={onChange}
              value={formState.additionalInfo}
            ></TextAreaInput>
            <TextBasic
              small
              innerHtml={blockContent.disclaimerText}
              className="opacity-60"
            ></TextBasic>
            <ShopButtonSubmit
              bgColor="#0066FF"
              textColor="#FFFFFF"
              text={blockContent.submitText}
              className="max-w-[130px] mt-8 self-end ml-auto"
            />
          </form>
        </div>
      </div>
    </>
  )
}

export const bookYourAppointmentBlock_inputs = {
  key: "bookYourAppointmentBlock",
  name: "Apointment Form Block",
  description: "A form to book an appointment",
  tags: ["Title", "Text", "Form"],
  inputs: [
    { inputType: "text", label: "Title", keyName: "title" },
    { inputType: "text", label: "Subtitle", keyName: "subtitle" },
    {
      inputType: "richText",
      label: "Intro text",
      keyName: "introText",
    },
    {
      inputType: "expandableInputs",
      label: "Form Text",
      childInputs: [
        {
          inputType: "text",
          label: "Title 1",
          keyName: "formTitle",
        },
        {
          inputType: "text",
          label: "First name label",
          keyName: "labelFirstName",
        },
        {
          inputType: "text",
          label: "Last name label",
          keyName: "labelLastName",
        },
        { inputType: "text", label: "Phone label", keyName: "labelPhone" },
        { inputType: "text", label: "Email label", keyName: "labelEmail" },
        {
          inputType: "text",
          label: "Date label",
          keyName: "labelPreferredDate",
        },
        {
          inputType: "text",
          label: "Title 2",
          keyName: "formTitle2",
        },
        {
          inputType: "text",
          label: "Text field placeholder",
          keyName: "placeholderAdditionalInfo",
          default: "What is your taste in music?",
        },
        {
          inputType: "text",
          label: "Submit button Text",
          keyName: "submitText",
          default: "Send",
        },
      ],
    },
    {
      inputType: "expandableInputs",
      label: "Event Texts",
      childInputs: [
        {
          inputType: "text",
          label: "Text on succesfully sent email after form submission",
          keyName: "successText",
        },
        {
          inputType: "text",
          label: "Text on email error after form submission",
          keyName: "errorText",
        },
        {
          inputType: "text",
          label: "Text to display while email is being sent",
          keyName: "processingText",
        },
      ],
    },
    {
      inputType: "richText",
      label: "Disclaimer text",
      keyName: "disclaimerText",
    },
  ],
  defaults: {
    ...BLOCKOPTIONS_DEFAULTS,
  },
}
