import React, { useContext } from "react"
import { Page } from "@rubendenbrok/bloqmatter-cms"
import { DataContext } from "../layout"
import DesktopHeader from "./DesktopHeader"
import MobileHeader from "./MobileHeader"

export default function Header() {
  function scrollToForm() {
    if (typeof window !== "undefined") {
      const contactElem = document.getElementById("contact")
      if (contactElem) {
        contactElem.scrollIntoView({ behavior: "smooth", block: "start" })
      }
    }
  }

  return (
    <>
      <DesktopHeader scrollToForm={scrollToForm} />
      <MobileHeader scrollToForm={scrollToForm} />
    </>
  )
}
