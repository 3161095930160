import { useState, useEffect, useRef, useCallback } from "react"

export const useScrollAnimation = (config?: {
  scrollHeight?: number
  scaleExponent?: number
  offset?: number
  disabled?: boolean
}) => {
  const [yFactor, setYFactor] = useState<number>(config?.disabled ? 1 : 0)
  const containerRef = useRef<HTMLElement | null>(null)
  const waitingForAnimation = useRef(false)
  const resetRef = useRef(false)

  //check if we're previewing the page, if so use the iframes contentWindow
  const currentWindow =
    typeof window !== "undefined"
      ? document.querySelector<HTMLIFrameElement>("#previewWindow")
        ? document.querySelector<HTMLIFrameElement>("#previewWindow")
            ?.contentWindow
        : window
      : null

  // scrollHeight is the amount of pixels the element should be in screen for the yFactor to return 1,
  // defaults to the height of the window
  const scrollHeight = config?.scrollHeight
    ? config.scrollHeight
    : currentWindow
    ? currentWindow.innerHeight
    : 500

  // exponent for the yFactor, to allow for quadradic or logarithmic animations
  // defaults to 1 (linear)
  const scaleExponent = config?.scaleExponent ? config.scaleExponent : 1

  //offset for the animation
  const offset = config?.offset ? config.offset : 0

  const ref = useCallback((node: HTMLElement) => {
    if (node !== null && typeof window !== "undefined" && !config?.disabled) {
      containerRef.current = node
      handleScroll()
    }
  }, [])

  function handleScroll() {
    if (!waitingForAnimation.current && containerRef.current) {
      waitingForAnimation.current = true
      requestAnimationFrame(() => {
        if (containerRef.current && currentWindow) {
          const amtOfPixOnScreen = -(
            containerRef.current.getBoundingClientRect().top -
            offset -
            currentWindow.innerHeight
          )
          const newYFactor = Math.pow(
            Math.min(Math.max(amtOfPixOnScreen / scrollHeight, 0), 1),
            scaleExponent
          )
          setYFactor(newYFactor)
          waitingForAnimation.current = false
        }
      })
    }
  }

  useEffect(() => {
    if (!config?.disabled) {
      if (typeof window !== "undefined") {
        const previewW =
          document.querySelector<HTMLIFrameElement>("#previewWindow")
        if (
          previewW &&
          previewW.contentWindow &&
          previewW.contentWindow.document
        ) {
          const layoutElement =
            previewW.contentWindow.document.querySelector("#layout")
          if (layoutElement) {
            layoutElement.addEventListener("scroll", handleScroll)
          }
        }
        window.addEventListener("scroll", handleScroll)
      }
      return () => {
        window.removeEventListener("scroll", handleScroll)
        const previewW =
          document.querySelector<HTMLIFrameElement>("#previewWindow")
        if (
          previewW &&
          previewW.contentWindow &&
          previewW.contentWindow.document
        ) {
          const layoutElement =
            previewW.contentWindow.document.querySelector("#layout")
          if (layoutElement) {
            layoutElement.removeEventListener("scroll", handleScroll)
          }
        }
      }
    } else {
      setYFactor(1)
    }
  }, [config?.disabled])

  return [ref, yFactor] as [(e: HTMLElement) => void, number]
}

export default useScrollAnimation
