import React, { useContext } from "react"
import getCascadingColor from "../../functions/getCascadingColor"
import { DataContext } from "../layout"
import H1 from "./H1"
import H3 from "./H3"
import TextBasic from "./TextBasic"

type TitleGroupProps = {
  blockContent: any
  textAlignClass: string
}

export default function TitleGroup({
  blockContent,
  textAlignClass,
}: TitleGroupProps) {
  const { cssVariables, siteOverviewData, thisPage } = useContext(DataContext)
  return (
    <div className="">
      {blockContent.displayTopTitleTag && (
        <TextBasic
          small
          className="mb-4 italic ml-0.5"
          color={cssVariables.colors[blockContent.topTitleTagColor]}
        >
          {blockContent.topTitleTagText}
        </TextBasic>
      )}
      <H1
        small={blockContent.smallerTitle}
        color={
          thisPage
            ? getCascadingColor(
                cssVariables,
                siteOverviewData,
                thisPage,
                blockContent
              ).title
            : ""
        }
        className={`${textAlignClass} overflow-y-visible font-titleAccent mb-4 block lg:whitespace-nowrap`}
      >
        {blockContent.title}
      </H1>
      <H1
        small={blockContent.smallerTitle}
        color={
          thisPage
            ? getCascadingColor(
                cssVariables,
                siteOverviewData,
                thisPage,
                blockContent
              ).title
            : ""
        }
        className={`${textAlignClass} overflow-y-visible font-titleAccent mb-4 block lg:w-[700px]`}
      >
        {blockContent.titleLine2}
      </H1>
      <H3
        small={blockContent.smallerTitle}
        color={
          thisPage
            ? getCascadingColor(
                cssVariables,
                siteOverviewData,
                thisPage,
                blockContent
              ).title
            : ""
        }
        setInnerHtml={blockContent.subtitle}
        className={`${textAlignClass} overflow-y-visible font-titleAccent`}
      ></H3>
    </div>
  )
}
