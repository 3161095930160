import React, {
  useContext,
  useState,
  useRef,
  useEffect,
  createContext,
} from "react"
import { getItemFromId } from "@rubendenbrok/bloqmatter-cms"
import getCascadingColor from "../../functions/getCascadingColor"
import { getAbsolutePositionStyle } from "../../styles/util"
import H1 from "../atoms/H1"
import H3 from "../atoms/H3"
import { DEFAULT_BLOCKOPTIONS_DEFAULTS } from "@rubendenbrok/bloqmatter-cms"

import { DataContext } from "../layout"
import BgVideo from "../molecules/BgVideo"
import { BLOCKOPTIONS_DEFAULTS } from "../../custom_blockoptions_inputs"
import H2 from "../atoms/H2"
import CategoryVertical from "./shopElements/CategoryVertical"
import TextBasic from "../atoms/TextBasic"
import ShopNavigationBar from "./shopElements/ShopNavigationBar"
import TypeSelectorModule from "./shopElements/TypeSelectorModule"
import OrderForm from "./shopElements/OrderForm"
import calculateTotalPrice from "./shopElements/caculateTotalPrice"

type props = any
export const ShopContext = createContext<any>({})

export default function ShopModuleBlock_Display({ blockContent }: props) {
  const {
    cssVariables,
    thisPage,
    siteOverviewData,
    videoData,
    processedImageData,
    localeData,
  } = useContext(DataContext)

  const [typeIndex, setTypeIndex] = useState<number>(0)
  const [variantIndex, setVariantIndex] = useState<null | number>(null)
  const [categoryIndex, setCategoryIndex] = useState<null | number>(null)
  const [isShowingOrderConfirm, setIsShowingOrderConfirm] = useState(false)
  const [selectedOptions, setSelectedOptions] = useState<(number | null)[]>([])

  useEffect(() => {
    if (
      !isNaN(typeIndex) &&
      typeof variantIndex === "number" &&
      typeof categoryIndex === "number"
    ) {
      const newOptionsArr: number | null[] = []
      blockContent.mainCategories[categoryIndex].variants[variantIndex].types[
        typeIndex
      ].upgrades?.forEach(() => newOptionsArr.push(null))
      setSelectedOptions(newOptionsArr)
    } else {
      setSelectedOptions([])
    }
  }, [typeIndex, variantIndex, categoryIndex])

  useEffect(() => {
    setTypeIndex(0)
  }, [variantIndex, categoryIndex])

  return (
    <ShopContext.Provider
      value={{
        typeIndex: typeIndex,
        setTypeIndex: setTypeIndex,
        variantIndex: variantIndex,
        setVariantIndex: setVariantIndex,
        categoryIndex: categoryIndex,
        setCategoryIndex: setCategoryIndex,
        isShowingOrderConfirm: isShowingOrderConfirm,
        setIsShowingOrderConfirm: setIsShowingOrderConfirm,

        blockContent: blockContent,
        totalPrice: calculateTotalPrice(
          blockContent,
          categoryIndex,
          variantIndex,
          typeIndex,
          selectedOptions
        ),
        selectedOptions: selectedOptions,
        setSelectedOptions: setSelectedOptions,
      }}
    >
      <div
        className="relative flex flex-col items-center pb-10 min-h-screen max-w-[100vw] px-4  mx-auto"
        style={{ color: cssVariables.colors[0] }}
      >
        {categoryIndex !== null &&
        variantIndex !== null &&
        isShowingOrderConfirm ? (
          <OrderForm currentLocale={localeData.currentLocale} />
        ) : (
          <>
            <div className="mt-8 h-6">
              {variantIndex === null && <ShopNavigationBar />}
            </div>
            {categoryIndex === null ? (
              <>
                <H2 className="mt-2">{blockContent.title}</H2>
                <H3>{blockContent.subtitle}</H3>
                <div className="flex w-full md:flex-row flex-col items-center md:items-start justify-center mt-6">
                  {blockContent.mainCategories.map(
                    (category: any, index: number) => {
                      return (
                        <CategoryVertical
                          key={category.name}
                          buttonText={category.buttonText}
                          image={category.image}
                          name={category.name}
                          isNew={true}
                          explanationText={category.explanationText}
                          animationDelay={index * 0.1}
                          onButtonClick={() => {
                            setCategoryIndex(index)
                            if (typeof window !== "undefined")
                              window.scrollTo({
                                left: 0,
                                top: 0,
                                behavior: "auto",
                              })
                          }}
                        ></CategoryVertical>
                      )
                    }
                  )}
                </div>
              </>
            ) : variantIndex === null ? (
              <>
                <H2 className="mt-2">
                  {blockContent.mainCategories[categoryIndex].title}
                </H2>
                <H3>{blockContent.mainCategories[categoryIndex].subtitle}</H3>
                <div className="flex w-full md:flex-row flex-col  items-center md:items-start  justify-center mt-6">
                  {blockContent.mainCategories[categoryIndex].variants.map(
                    (variant: any, index: number) => {
                      return (
                        <CategoryVertical
                          key={variant.name}
                          buttonText={variant.buttonText}
                          image={variant.image}
                          name={variant.name}
                          isNew={true}
                          animationDelay={index * 0.1}
                          explanationText={variant.explanationText}
                          onButtonClick={() => {
                            setVariantIndex(index)
                            if (typeof window !== "undefined")
                              window.scrollTo({
                                left: 0,
                                top: 0,
                                behavior: "auto",
                              })
                          }}
                        ></CategoryVertical>
                      )
                    }
                  )}
                </div>
              </>
            ) : (
              <TypeSelectorModule />
            )}
          </>
        )}
      </div>
    </ShopContext.Provider>
  )
}

export const ShopModuleBlock_Inputs = {
  key: "shopModuleBlock",
  name: "Shop Module Block",
  tags: ["Shop"],
  description: "A custom KAD-Audio configurable shop module.",
  inputs: [
    {
      inputType: "text",
      label: "Title",
      defaultVal: "KAD K-series",
      keyName: "title",
    },
    {
      inputType: "text",
      label: "Subtitle",
      defaultVal: "Speaker Systems",
      keyName: "subtitle",
    },

    {
      inputType: "inputArray",
      label: "Main Categories",
      itemName: "Category",
      keyName: "mainCategories",
      childNameKey: "name",
      childInputs: [
        {
          inputType: "expandableInputs",
          label: "Text",
          childInputs: [
            { inputType: "text", keyName: "name", label: "Name" },
            {
              inputType: "richText",
              label: "Explanation text",
              keyName: "explanationText",
            },
            {
              inputType: "text",
              keyName: "buttonText",
              label: "Button text",
              defaultVal: "Explore",
            },
            { inputType: "checkbox", keyName: "isNew", label: "New?" },
            {
              inputType: "text",
              label: "Title",
              hoverHelp:
                "Will be used as title when this category is selected in the shop",
              keyName: "title",
            },
            {
              inputType: "text",
              label: "Subtitle",
              hoverHelp:
                "Will be used as subtitle when this category is selected in the shop",
              keyName: "subtitle",
            },
          ],
        },

        { inputType: "image", keyName: "image", label: "Image" },

        {
          inputType: "inputArray",
          label: "Variants",
          itemName: "Variant",
          keyName: "variants",
          childNameKey: "name",
          childInputs: [
            {
              inputType: "expandableInputs",
              label: "Text",
              childInputs: [
                { inputType: "text", keyName: "name", label: "Name" },
                {
                  inputType: "richText",
                  label: "Explanation text",
                  keyName: "explanationText",
                },
                {
                  inputType: "text",
                  keyName: "buttonText",
                  label: "Button text",
                  defaultVal: "Explore",
                },
                {
                  inputType: "text",
                  label: "Title",
                  hoverHelp:
                    "Will be used as title when this variant is selected in the shop",
                  keyName: "title",
                },
                { inputType: "checkbox", keyName: "isNew", label: "New?" },
              ],
            },
            { inputType: "image", keyName: "image", label: "Image" },
            {
              inputType: "inputArray",
              label: "Types",
              itemName: "Type",
              keyName: "types",
              childNameKey: "name",
              childInputs: [
                {
                  inputType: "expandableInputs",
                  label: "Text",
                  childInputs: [
                    {
                      inputType: "text",
                      keyName: "name",
                      label: "Type Name",
                      defaultVal: "",
                    },
                    {
                      inputType: "richText",
                      label: "Additional text",
                      keyName: "explanationText",
                    },
                    {
                      inputType: "text",
                      keyName: "price",
                      label:
                        "Price incl. VAT (number without € sign, use . for decimals)",
                      defaultVal: "0",
                    },
                    { inputType: "checkbox", keyName: "isNew", label: "New?" },
                  ],
                },
                {
                  inputType: "image",
                  keyName: "imageFront",
                  label: "Image front",
                },
                {
                  inputType: "image",
                  keyName: "imageBack",
                  label: "Image back",
                },
                {
                  inputType: "inputArray",
                  label: "Extra options / upgades",
                  itemName: "Upgrade",
                  keyName: "upgrades",
                  childNameKey: "title",
                  childInputs: [
                    {
                      inputType: "text",
                      label: "Upgrade title",
                      keyName: "title",
                    },
                    {
                      inputType: "inputArray",
                      label: "Options",
                      itemName: "Option",
                      keyName: "options",
                      childInputs: [
                        {
                          inputType: "richText",
                          label: "Option text",
                          keyName: "text",
                        },
                        {
                          inputType: "text",
                          label:
                            "Option price incl. VAT (number without € sign, use . for decimals)",
                          keyName: "price",
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
    {
      inputType: "expandableInputs",
      label: "Order form text",
      childInputs: [
        {
          inputType: "text",
          label: "Page subtitle",
          keyName: "formSubtitle",
        },
        {
          inputType: "text",
          label: "Form upper title",
          keyName: "formUpperTitle",
        },
        {
          inputType: "text",
          label: "First name label",
          keyName: "labelFirstName",
        },
        {
          inputType: "text",
          label: "Last name label",
          keyName: "labelLastName",
        },
        {
          inputType: "text",
          label: "Country label",
          keyName: "labelCountry",
        },
        {
          inputType: "text",
          label: "Address label",
          keyName: "labelAddress",
        },
        {
          inputType: "text",
          label: "Postal code label",
          keyName: "labelPostalCode",
        },
        {
          inputType: "text",
          label: "City label",
          keyName: "labelCity",
        },
        { inputType: "text", label: "Phone label", keyName: "labelPhone" },
        { inputType: "text", label: "Email label", keyName: "labelEmail" },
        {
          inputType: "text",
          label: "Nesletter checkbox text",
          keyName: "labelNewsletterSubscribe",
        },
        {
          inputType: "text",
          label: "Additional info label",
          keyName: "labelAdditionalInfo",
        },
        {
          inputType: "text",
          label: "Additional info placeholder text",
          keyName: "placeholderAdditionalInfo",
        },
        {
          inputType: "text",
          label: "Order button text",
          keyName: "orderButtonText",
        },
      ],
    },
    {
      inputType: "expandableInputs",
      label: "Additional text",
      childInputs: [
        {
          inputType: "text",
          label: "Privacy statement",
          keyName: "privacyStatement",
        },
        {
          inputType: "text",
          label: "Successful order title",
          keyName: "orderSuccessTitle",
        },
        {
          inputType: "text",
          label: "Successful order text",
          keyName: "orderSuccessText",
        },
        {
          inputType: "text",
          label: "Go back title",
          keyName: "orderCancelTitle",
        },
        {
          inputType: "text",
          label: "Go back text",
          keyName: "orderCancelText",
        },
        {
          inputType: "text",
          label: "Go back button text",
          keyName: "orderCancelButtonText",
        },
        {
          inputType: "text",
          label: "Processing order title",
          keyName: "orderProcessingTitle",
        },
        {
          inputType: "text",
          label: "Processing order text",
          keyName: "orderProcessingText",
        },
        {
          inputType: "text",
          label: "Failed order title",
          keyName: "orderFailTitle",
        },
        {
          inputType: "text",
          label: "Failed order text",
          keyName: "orderFailText",
        },
        {
          inputType: "text",
          label: "Close button text",
          keyName: "closeButtonText",
        },
      ],
    },
  ],
  defaults: {
    ...BLOCKOPTIONS_DEFAULTS,
  },
}
