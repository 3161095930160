import React, { ReactNode, useState } from "react"

import { Link } from "@rubendenbrok/bloqmatter-cms"
import BloqmatterLink from "./BloqmatterLink"
import TextBasic from "./TextBasic"

type elementProps = {
  className?: string
  color1: string
  color2: string
  link: Link
  flip?: boolean
  center?: boolean
}

export default function Button({
  className,
  color1,
  color2,
  link,
  flip,
  center,
}: elementProps) {
  const [hover, setHover] = useState(false)
  const arrowSize = 14
  return (
    <div className="sm:mt-8 mt-6">
      <BloqmatterLink link={link}>
        <div
          onMouseEnter={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
          style={{
            backgroundColor: color1,
          }}
          className={`relative inline-block py-3 px-8 rounded-md overflow-hidden transition-colors`}
        >
          <TextBasic
            className="font-titleAccent text-center transition-colors"
            color={color2}
          >
            {link?.text}
          </TextBasic>
          <div
            className={`absolute w-full h-full inset-0 bg-black ${
              hover ? "opacity-25" : "opacity-0"
            } transition-opacity`}
          ></div>
        </div>
      </BloqmatterLink>
    </div>
  )
}
