/*
BESCHRIJVING:
Een block dat een video of afbeelding laat zien naast een tekst met titel en subtitel. 
De gebruiker kan kiezen of de media op mobile ook te zien moet zijn, als dat zo is komt die boven de tekst te staan.

De media bewaart altijd zijn oorspronkelijke aspect ratio, wordt niet gecropt, en wordt nooit groter dan zijn oorspronkelijke formaat.

DATA:
{
  title: string
  subtitle: string
  text: html string
  textAlign: 'start' | 'center' | 'end'
  mediaFormat: 'video' | 'image'
  video: string (video ID)
  image: string (image ID)
  switchOrder: boolean (Media verschijnt rechts ipv links van tekst if true)
  showMediaOnMobile: boolean
  buttons: CTA Input group - default: {buttonlink: Link, buttonColor1: color, buttonColor2: color}

}
*/

import React, { useContext, useRef } from "react"
import { getItemFromId } from "@rubendenbrok/bloqmatter-cms"
import getCascadingColor from "../../functions/getCascadingColor"
import H3 from "../atoms/H3"
import H1 from "../atoms/H1"
import TextBasic from "../atoms/TextBasic"

import { DataContext } from "../layout"
import BgVideo from "../molecules/BgVideo"
import Button from "../atoms/Button"
import ResponsiveImg from "../molecules/ResponsiveImg"
import useIsInScreen from "../../hooks/useIsInScreen"
import { fadeSlideIn } from "../../styles/animations"
import { DEFAULT_BLOCKOPTIONS_DEFAULTS } from "@rubendenbrok/bloqmatter-cms"
import useScrollAnimation from "../../hooks/useScrollAnimation"
import TitleGroup from "../atoms/TitleGroup"
import { BLOCKOPTIONS_DEFAULTS } from "../../custom_blockoptions_inputs"
import ResponsiveBgImg from "../molecules/ResponsiveBgImg"

type props = any

export default function MediaTextBlock_Display({ blockContent }: props) {
  const {
    cssVariables,
    thisPage,
    siteOverviewData,
    videoData,
    processedImageData,
  } = useContext(DataContext)
  const videoObj = getItemFromId(blockContent.video, videoData)
  const imgObj = getItemFromId(blockContent.image, processedImageData)
  const [scrollAnimRef, yFactor] = useScrollAnimation({
    disabled: !blockContent.imageScrollAnimation,
    scrollHeight:
      typeof window !== "undefined" ? window?.innerHeight * 1.2 : 1000,
    scaleExponent: 0.3,
  })

  const [animRef, isVisible] = useIsInScreen("100px")
  const scrollAnimation = isVisible ? fadeSlideIn.show : fadeSlideIn.hide

  const MIN_MEDIA_WIDTH = "340px"
  const MEDIA_ASPECT_RATIO =
    blockContent.mediaFormat === "image"
      ? imgObj?.originalH / imgObj?.originalW
      : videoObj?.metadata.height / videoObj?.metadata.width
  const MEDIA_MAX_WIDTH =
    blockContent.mediaFormat === "image"
      ? imgObj?.originalW
      : videoObj?.metadata.width
  const MEDIA_MAX_HEIGHT =
    blockContent.mediaFormat === "image"
      ? imgObj?.originalH
      : videoObj?.metadata.height

  const mobileTextAlignClass =
    blockContent.textAlignMobile === "end"
      ? "text-right"
      : blockContent.textAlignMobile === "start"
      ? "text-left"
      : "text-center"

  const textAlignClass =
    blockContent.textAlign === "start"
      ? `sm:text-left ${mobileTextAlignClass}`
      : blockContent.textAlign === "center"
      ? `sm:text-center ${mobileTextAlignClass}`
      : `sm:text-right ${mobileTextAlignClass}`

  return (
    <div
      className={`w-full relative flex flex-col justify-between items-center ${
        blockContent.switchOrder ? "lg:flex-row-reverse" : "lg:flex-row"
      }`}
    >
      {((blockContent.mediaFormat === "image" && blockContent.image) ||
        (blockContent.mediaFormat === "video" && blockContent.video)) && (
        <div
          ref={scrollAnimRef}
          className={`relative flex-shrink-0 w-full ${
            blockContent.showMediaOnMobile ? "block" : "hidden lg:block"
          } ${
            blockContent.mediaFormat === "image"
              ? blockContent.switchOrder
                ? " sm:pl-0 sm:pr-10 px-6"
                : " sm:pr-0 sm:pl-10 px-6"
              : ""
          }`}
          style={{
            width: `min(max(40vw, ${MIN_MEDIA_WIDTH}), ${MEDIA_MAX_WIDTH}px)`,
            height: `min(${MEDIA_MAX_HEIGHT}px, calc(${MEDIA_ASPECT_RATIO} * max(50vw, ${MIN_MEDIA_WIDTH})), 600px)`,
            maxHeight: MEDIA_MAX_HEIGHT + "px",
            maxWidth: ``,
            transform: `scale(${0.6 + yFactor * 0.45})`,
          }}
        >
          {blockContent.mediaFormat === "image" ? (
            <ResponsiveImg image={blockContent.image} imageCrop={false} />
          ) : (
            <BgVideo videoId={blockContent.video} />
          )}
        </div>
      )}

      <div
        ref={animRef}
        className={`flex flex-col w-full lg:w-1/2  ${textAlignClass}  px-6 lg:px-12 pb-8 lg:pb-0  ${
          blockContent.showMediaOnMobile ? "lg:mt-0 mt-12" : ""
        } ${
          blockContent.textAlign === "start"
            ? "items-start"
            : blockContent.textAlign === "end"
            ? "lg:items-end items-start"
            : "lg:items-center items-start"
        }`}
        style={{
          ...fadeSlideIn.transition,
          ...scrollAnimation,
        }}
      >
        <TitleGroup
          blockContent={blockContent}
          textAlignClass={textAlignClass}
        />
        {blockContent.showLogo && blockContent.logoImage && (
          <div
            className={`h-12 w-24 mt-3  ${
              blockContent.textAlign === "start"
                ? "mr-auto"
                : blockContent.textAlign === "end"
                ? "lg:ml-auto"
                : "lg:mx-auto"
            }`}
          >
            <ResponsiveImg image={blockContent.logoImage} imageCrop={false} />
          </div>
        )}
        {blockContent.text && (
          <TextBasic
            color={
              thisPage
                ? getCascadingColor(
                    cssVariables,
                    siteOverviewData,
                    thisPage,
                    blockContent
                  ).text
                : "black"
            }
            small={blockContent.smallText}
            className={`mt-10 ${textAlignClass}`}
            innerHtml={blockContent.text}
          ></TextBasic>
        )}
        {blockContent.buttons &&
          blockContent.buttons.map((button: any, index: number) => {
            return (
              <Button
                key={index}
                link={button.buttonLink}
                color1={cssVariables.colors[button.buttonColor1]}
                color2={cssVariables.colors[button.buttonColor2]}
                flip={blockContent.textAlign === "end"}
              ></Button>
            )
          })}
      </div>
    </div>
  )
}

export const MediaTextBlock_Inputs = {
  key: "mediaTextBlock",
  name: "Media / Text Block",
  tags: ["Text", "Media", "Image", "Video"],
  description:
    "A block of text with title and optional CTA next to an image or video.",
  inputs: [
    {
      inputType: "expandableInputs",
      label: "Text",
      childInputs: [
        { inputGroupName: "titleInputGroup", type: "group" },
        {
          inputType: "richText",
          label: "Text",
          keyName: "text",
          defaultVal: "",
        },
        {
          inputType: "radio",
          labelArr: ["Left", "Center", "Right"],
          valueArr: ["start", "center", "end"],
          label: "Text align desktop",
          keyName: "textAlign",
          defaultVal: "center",
        },
        {
          inputType: "radio",
          labelArr: ["Left", "Center", "Right"],
          valueArr: ["start", "center", "end"],
          label: "Text align mobile",
          keyName: "textAlignMobile",
          defaultVal: "center",
        },
        {
          inputType: "checkbox",
          label: "Smaller text",
          keyName: "smallText",
        },
        {
          inputType: "conditional",
          label: "Show logo image in text box",
          keyName: "showLogo",
          childInputs: [
            { inputType: "image", keyName: "logoImage", label: "Logo Image" },
          ],
        },
      ],
    },
    {
      inputType: "expandableInputs",
      label: "Media",
      childInputs: [
        {
          inputType: "conditionalRadio",
          label: "Media format",
          keyName: "mediaFormat",
          labelArr: ["Video", "Image"],
          valueArr: ["video", "image"],
          childInputsArr: [
            [
              {
                inputType: "video",
                label: "Video",
                keyName: "video",
                defaultVal: "",
              },
            ],
            [
              {
                inputType: "image",
                label: "Image",
                keyName: "image",
                defaultVal: "",
              },
              {
                inputType: "checkbox",
                label: "Animate image on scroll",
                keyName: "imageScrollAnimation",
              },
            ],
          ],
        },
      ],
    },
    {
      inputType: "checkbox",
      label: "Media right of text",
      keyName: "switchOrder",
    },
    {
      inputType: "checkbox",
      label: "Show media on mobile",
      keyName: "showMediaOnMobile",
    },
    { type: "group", inputGroupName: "ctaInputGroup" },
  ],
  defaults: {
    ...BLOCKOPTIONS_DEFAULTS,
  },
}
