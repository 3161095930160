export type WebsiteColorData = {
  colors_text: number
  colors_bg: number
  colors_title: number
  favicon?: string
}

export type PageColorData = {
  colors_text: string | number
  colors_bg: string | number
  colors_title: string | number
}

export type cssVariables = {
  colors: string[]
  [x: string]: any
}

export interface inputData extends PageColorData {
  [x: string]: any
}

type outputType = {
  text: string
  bg: string
  title: string
}

export default function getCascadingColor(
  cssVariables: cssVariables,
  siteOverviewData: WebsiteColorData,
  thisPage: inputData,
  blockContent?: inputData
): outputType {
  // take the website default colors from siteOverviewData as defaults
  const output = {
    text:
      typeof siteOverviewData.colors_text !== "undefined"
        ? cssVariables.colors[siteOverviewData.colors_text]
        : "#000000",
    title:
      typeof siteOverviewData.colors_title !== "undefined"
        ? cssVariables.colors[siteOverviewData.colors_title]
        : "#000000",
    bg:
      typeof siteOverviewData.colors_bg !== "undefined"
        ? cssVariables.colors[siteOverviewData.colors_bg]
        : "#000000",
  }

  //check if the page has colors that differ from the defaults
  if (typeof thisPage.colors_text === "number") {
    output.text = cssVariables.colors[thisPage.colors_text]
  }
  if (typeof thisPage.colors_title === "number") {
    output.title = cssVariables.colors[thisPage.colors_title]
  }
  if (typeof thisPage.colors_bg === "number") {
    output.bg = cssVariables.colors[thisPage.colors_bg]
  }

  //if blockContent is provided (so the requested color is for a block) check which colors are specified
  if (typeof blockContent?.colors_bg === "number") {
    output.bg = cssVariables.colors[blockContent.colors_bg]
  }
  if (blockContent?.colors_bg === "none") {
    output.bg = "#00000000"
  }
  if (typeof blockContent?.colors_text === "number") {
    output.text = cssVariables.colors[blockContent.colors_text]
  }
  if (typeof blockContent?.colors_title === "number") {
    output.title = cssVariables.colors[blockContent.colors_title]
  }

  return output
}
