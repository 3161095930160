import React from "react"

interface AnimatedMenuOpenCloseIconProps
  extends React.HTMLAttributes<HTMLDivElement> {
  color?: string
  isOpen?: boolean
  onOpen: Function
  onClose: Function
}

export default function AnimatedMenuOpenCloseIcon({
  isOpen,
  onOpen,
  onClose,
  className,
  color,
  ...rest
}: AnimatedMenuOpenCloseIconProps) {
  return (
    <div
      className={`flex w-10 h-12 scale-[0.6]  flex-col justify-between p-2 cursor-pointer select-none ${className}`}
      onClick={() => {
        if (isOpen) {
          onClose()
        } else {
          onOpen()
        }
      }}
      style={{
        zIndex: 999,
      }}
      {...rest}
    >
      <div
        style={{ backgroundColor: color ? color : "black" }}
        className={`w-full h-1 absolute origin-center left-0 ${
          isOpen ? "top-1/2 rotate-45" : "top-1/4 rotate-0"
        } transition-all ease-in-out rounded-sm duration-500`}
      ></div>
      <div
        style={{ backgroundColor: color ? color : "black" }}
        className={`h-1 absolute origin-center left-0 top-1/2 ${
          isOpen ? "w-0 opacity-20" : "opacity-100 w-full "
        } transition-all ease-in-out rounded-sm duration-500`}
      ></div>
      <div
        style={{ backgroundColor: color ? color : "black" }}
        className={`w-full h-1 absolute origin-center left-0 ${
          isOpen ? "top-1/2 -rotate-45" : "top-3/4 rotate-0"
        } transition-all ease-in-out rounded-sm duration-500`}
      ></div>
    </div>
  )
}
