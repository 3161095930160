import React, { useContext } from "react"
import TextBasic from "../../atoms/TextBasic"
import { ShopContext } from "../ShopModuleBlock"

type Props = {}

export default function ShopNavigationBar({}: Props) {
  const {
    blockContent,
    categoryIndex,
    variantIndex,
    setCategoryIndex,
    setVariantIndex,
  } = useContext(ShopContext)
  return (
    <TextBasic className="inline-flex" sizeClass="font-xs">
      {categoryIndex !== null && variantIndex === null && (
        <>
          <a
            className="cursor-pointer underline opacity-50 hover:opacity-100 transition-opacity"
            onClick={() => setCategoryIndex(null)}
          >
            Shop
          </a>
          <div className="ml-2 opacity-50">
            &#62;
            <div className="inline-block ml-2 ">
              {blockContent.mainCategories[categoryIndex].title}
            </div>
          </div>
        </>
      )}
      {variantIndex !== null && categoryIndex !== null && (
        <>
          <a
            className="cursor-pointer underline opacity-50 hover:opacity-100 transition-opacity"
            onClick={() => {
              setCategoryIndex(null)
              setVariantIndex(null)
              if (typeof window !== "undefined")
                window.scrollTo({ left: 0, top: 0, behavior: "auto" })
            }}
          >
            Shop
          </a>
          <div className="ml-2 opacity-50 hover:opacity-100 transition-opacity">
            &#62;
            <a
              className="inline-block ml-2 underline cursor-pointer"
              onClick={() => {
                setVariantIndex(null)
                if (typeof window !== "undefined")
                  window.scrollTo({ left: 0, top: 0, behavior: "auto" })
              }}
            >
              {blockContent.mainCategories[categoryIndex].title}
            </a>
          </div>
          <div className="ml-2 opacity-50">
            &#62;
            <div className="inline-block ml-2 ">
              {
                blockContent.mainCategories[categoryIndex].variants[
                  variantIndex
                ].name
              }
            </div>
          </div>
        </>
      )}
    </TextBasic>
  )
}
