function createFancyPriceStringsFromPriceWithVat(input: string | number) {
  const vatPercentage = 21

  const fancyPriceStringInclVat = createFancyPriceString(parseFloat(input))
  const fancyPriceStringExVat = createFancyPriceString(
    (parseFloat(input) / (100 + vatPercentage)) * 100
  )

  return { inclVat: fancyPriceStringInclVat, exVat: fancyPriceStringExVat }

  function createFancyPriceString(input: number) {
    const split = input.toString().split(".")
    let beforeComma = Array.from(split[0])
    if (beforeComma.length > 3) {
      const newArr = [...beforeComma]
      beforeComma.forEach((num, index) => {
        if (index % 3 === 0 && index !== 0) {
          newArr.splice(
            newArr.length - (index + Math.floor(index / 3)) + 1,
            0,
            ","
          )
        }
      })
      beforeComma = [...newArr]
    }
    let behindComma = ""
    if (split.length === 1) {
      behindComma = "00"
    } else {
      behindComma = split[1].toString()
      if (behindComma.length === 1) behindComma = behindComma + "0"
      if (behindComma.length > 2) behindComma = behindComma.substring(0, 2)
    }
    return `${beforeComma.join("")}.${behindComma}`
  }
}
export default createFancyPriceStringsFromPriceWithVat
