import React from "react"
import ResponsiveImg from "../../molecules/ResponsiveImg"

type Props = {
  image: string
  animated?: boolean
  animationDelay?: number
  onClick?: Function
}

export default function SpeakerImage({
  image,
  animated,
  onClick,
  animationDelay,
}: Props) {
  return (
    <div
      className={`w-[93px] h-[300px]  xl:w-[125px] xl:h-[405px] ${
        animated && "speakerImgFade"
      }`}
      style={{ animationDelay: `${animationDelay}s` }}
    >
      <ResponsiveImg image={image} noFade />
    </div>
  )
}
