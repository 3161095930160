import React, {
  useRef,
  useReducer,
  ChangeEvent,
  useState,
  useContext,
} from "react"
import H2 from "../../atoms/H2"
import H3 from "../../atoms/H3"
import TextBasic from "../../atoms/TextBasic"
import { TextInput, TextAreaInput } from "../../atoms/FormInputs"
import ConfigurationConfirmBox from "./ConfigurationConfirmBox"
import ShopButton, { ShopButtonSubmit } from "./ShopButton"
import { ShopContext } from "../ShopModuleBlock"

type StateType = {
  firstName: string
  lastName: string
  country: string
  address: string
  postalCode: string
  city: string
  phone: string
  email: string
  newsletterSubscribe: boolean
  additionalInfo: string
}

type Props = { currentLocale: string | null }

export default function OrderForm({ currentLocale }: Props) {
  const {
    blockContent,
    variantIndex,
    typeIndex,
    categoryIndex,
    cablesOptionIsSelected,
    selectedOptions,
    totalPrice,
  } = useContext(ShopContext)
  const initState: StateType = {
    firstName: "",
    lastName: "",
    country: "",
    address: "",
    postalCode: "",
    city: "",
    phone: "",
    email: "",
    newsletterSubscribe: false,
    additionalInfo: "",
  }

  const reducer = (state: StateType, update: Partial<StateType>) => {
    return { ...state, ...update }
  }

  const [formState, dispatch] = useReducer(reducer, initState)
  const formContentRef = useRef(initState)
  const formRef = useRef<HTMLFormElement>(null)
  const [orderState, setOrderState] = useState<
    "ordering" | "processing" | "success" | "cancel" | "error"
  >("ordering")

  const onChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    dispatch({ [e.target.name]: e.target.value })
    formContentRef.current = {
      ...formContentRef.current,
      [e.target.name]: e.target.value,
    }
  }
  console.log(
    blockContent.mainCategories[categoryIndex].variants[variantIndex].types[
      typeIndex
    ]
  )
  console.log(selectedOptions)

  const handleSubmit = async (e: any) => {
    e.preventDefault()
    const upgradeStrings: string[] = []

    // create a html string from selected upgrades to the product
    if (
      blockContent.mainCategories[categoryIndex].variants[variantIndex].types[
        typeIndex
      ].upgrades
    ) {
      blockContent.mainCategories[categoryIndex].variants[variantIndex].types[
        typeIndex
      ].upgrades.forEach((upgrade: any, index: number) => {
        const optionString =
          selectedOptions[index] === null
            ? "none"
            : upgrade.options[selectedOptions[index]].text
        upgradeStrings.push(`<p>${upgrade.title}:<br/> ${optionString}</p>`)
      })
    }
    console.log(upgradeStrings)
    const upgrades = upgradeStrings.join("<br/>")
    console.log(upgrades)
    if (typeof window !== "undefined") {
      window.scrollTo({ left: 0, top: 0, behavior: "smooth" })
    }
    const data: { [x: string]: any } = { ...formContentRef.current }
    data.product =
      blockContent.mainCategories[categoryIndex].variants[variantIndex].types[
        typeIndex
      ].name
    data.cablesOptionIsSelected = cablesOptionIsSelected
    data.totalPrice = totalPrice
    data.upgrades = upgrades
    data.locale = currentLocale

    const url = `/.netlify/functions/sendOrderMail?data=${JSON.stringify(data)}`

    try {
      setOrderState("processing")
      const response = await fetch(url)
      if (response.status === 200) {
        setOrderState("success")
      } else {
        console.log(response)
        setOrderState("error")
      }
    } catch (e) {
      console.warn(e)
      setOrderState("error")
    }
  }

  return (
    <div className="flex flex-col items-center w-full max-w-3xl mt-16">
      <H2>{blockContent.mainCategories[categoryIndex].title}</H2>
      <H3>{blockContent.formSubtitle}</H3>
      <div className="w-full mt-8">
        <ConfigurationConfirmBox
          orderState={orderState}
          setOrderState={setOrderState}
        />
      </div>
      <H3 className="self-start mt-12 mb-4 font-semibold">
        {blockContent.formUpperTitle}
      </H3>
      <form
        ref={formRef}
        className="w-full flex flex-col"
        onSubmit={handleSubmit}
      >
        <TextInput
          name="firstName"
          onChange={onChange}
          label={blockContent.labelFirstName}
          required
          value={formState.firstName}
        />
        <TextInput
          name="lastName"
          onChange={onChange}
          label={blockContent.labelLastName}
          required
          value={formState.lastName}
        />
        <TextInput
          name="country"
          onChange={onChange}
          label={blockContent.labelCountry}
          required
          value={formState.country}
        />
        <TextInput
          name="address"
          onChange={onChange}
          label={blockContent.labelAddress}
          required
          value={formState.address}
        />
        <TextInput
          name="postalCode"
          onChange={onChange}
          label={blockContent.labelPostalCode}
          required
          value={formState.postalCode}
        />
        <TextInput
          name="city"
          onChange={onChange}
          label={blockContent.labelCity}
          required
          value={formState.city}
        />
        <TextInput
          name="phone"
          type="phone"
          onChange={onChange}
          label={blockContent.labelPhone}
          required
          value={formState.phone}
        />
        <TextInput
          name="email"
          type="email"
          onChange={onChange}
          label={blockContent.labelEmail}
          required
          value={formState.email}
        />
        <div className="flex mt-4">
          <input
            type="checkbox"
            name="newsletterSubscribe"
            onChange={onChange}
          />
          <TextBasic className="ml-4" small>
            {blockContent.labelNewsletterSubscribe}
          </TextBasic>
        </div>
        <H3 className="mt-16 font-semibold mb-2">
          {blockContent.labelAdditionalInfo}
        </H3>
        <TextAreaInput
          name="additionalInfo"
          placeholder={blockContent.placeholderAdditionalInfo}
          onChange={onChange}
          value={formState.additionalInfo}
        ></TextAreaInput>
        <TextBasic small className="opacity-50 mt-8">
          {blockContent.privacyStatement}
        </TextBasic>
        <ShopButtonSubmit
          bgColor="#0066FF"
          textColor="#FFFFFF"
          text={blockContent.orderButtonText}
          className="max-w-[130px] mt-8 self-end"
        />{" "}
      </form>
    </div>
  )
}
