/*
BESCHRIJVING:
Een block dat een video of afbeelding laat zien naast een tekst met titel en subtitel. 
De gebruiker kan kiezen of de media op mobile ook te zien moet zijn, als dat zo is komt die boven de tekst te staan.

De media bewaart altijd zijn oorspronkelijke aspect ratio, wordt niet gecropt, en wordt nooit groter dan zijn oorspronkelijke formaat.

DATA:
{
  title: string
  subtitle: string
  text: html string
  textAlign: 'start' | 'center' | 'end'
  blockPosition: 'start' | 'center' | 'end'
  mediaFormat: 'video' | 'image'
  video: string (video ID)
  image: string (image ID)
  switchOrder: boolean (Media verschijnt rechts ipv links van tekst if true)
  showMediaOnMobile: boolean
  buttons: CTA Input group - default: {buttonlink: Link, buttonColor1: color, buttonColor2: color}

}
*/

import React, { useContext, useRef } from "react"
import { getItemFromId, ImageData } from "@rubendenbrok/bloqmatter-cms"
import getCascadingColor from "../../functions/getCascadingColor"
import H3 from "../atoms/H3"
import H1 from "../atoms/H1"
import TextBasic from "../atoms/TextBasic"

import { DataContext } from "../layout"
import BgVideo from "../molecules/BgVideo"
import Button from "../atoms/Button"
import ResponsiveImg from "../molecules/ResponsiveImg"
import useIsInScreen from "../../hooks/useIsInScreen"
import { fadeSlideIn } from "../../styles/animations"
import { DEFAULT_BLOCKOPTIONS_DEFAULTS } from "@rubendenbrok/bloqmatter-cms"
import useScrollAnimation from "../../hooks/useScrollAnimation"
import TitleGroup from "../atoms/TitleGroup"
import { BLOCKOPTIONS_DEFAULTS } from "../../custom_blockoptions_inputs"

type props = any

export default function TriangleMediaTextBlock_Display({
  blockContent,
  blockIndex,
}: props) {
  const {
    cssVariables,
    thisPage,
    siteOverviewData,
    videoData,
    processedImageData,
  } = useContext(DataContext)
  const videoObj = getItemFromId(blockContent.video, videoData)
  const imgObj = getItemFromId(blockContent.image, processedImageData)
  const [scrollAnimRef, yFactor] = useScrollAnimation({
    disabled: !blockContent.imageScrollAnimation,
    scrollHeight:
      typeof window !== "undefined" ? window?.innerHeight * 1.2 : 1000,
    scaleExponent: 0.3,
  })
  const [triangleAnimRef, triangleYFactor] = useScrollAnimation({
    scaleExponent: 0.3,
    scrollHeight:
      typeof window !== "undefined" ? window?.innerHeight * 1.2 : 1000,
    offset: 500,
    disabled: !blockContent.triangleScrollAnimation,
  })

  const triangleImageObj = getItemFromId<ImageData>(
    blockContent.triangleImage,
    processedImageData
  )

  const [animRef, isVisible] = useIsInScreen("100px")
  const scrollAnimation = isVisible ? fadeSlideIn.show : fadeSlideIn.hide

  const MEDIA_ASPECT_RATIO =
    blockContent.mediaFormat === "image"
      ? imgObj?.originalH / imgObj?.originalW
      : videoObj?.metadata.height / videoObj?.metadata.width

  const MEDIA_MAX_HEIGHT =
    blockContent.mediaFormat === "image"
      ? imgObj?.originalH
      : videoObj?.metadata.height

  const mobileTextAlignClass =
    blockContent.textAlignMobile === "end"
      ? "text-right"
      : blockContent.textAlignMobile === "start"
      ? "text-left"
      : "text-center"

  const textAlignClass =
    blockContent.textAlign === "start"
      ? `sm:text-left ${mobileTextAlignClass}`
      : blockContent.textAlign === "center"
      ? `sm:text-center ${mobileTextAlignClass}`
      : `sm:text-right ${mobileTextAlignClass}`

  const heightClass = "h-[calc(35vw*1.2)]]"
  const widthClass = "w-[35vw]"
  const leftClass = "lg:-left-[max(calc(50vw-625px),0px)]"

  return (
    <div
      className={`w-full flex lg:flex-row flex-col lg:items-center items-start`}
    >
      <div
        ref={triangleAnimRef}
        className={`lg:w-1/3 lg:block 
      hidden
         relative overflow-visible ${heightClass}`}
        style={{
          opacity: Math.min(triangleYFactor, 1),

          transform: `translateX(${(1 - triangleYFactor) * -300}px)`,
        }}
      >
        {triangleImageObj && (
          <svg
            className={`absolute pointer-events-none top-1/2 -translate-y-1/2 overflow-visible ${widthClass} ${heightClass} ${leftClass}`}
            viewBox={"0 0 5 10"}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <defs>
              <pattern
                id={`img-${triangleImageObj.id}-${blockIndex}`}
                width="100%"
                height="100%"
                patternUnits="userSpaceOnUse"
                x="0"
                y="0"
              >
                <image
                  href={triangleImageObj.urls.fallback.l} // which size to use?
                  x="0"
                  y="0"
                  width="100%"
                  height="100%"
                  preserveAspectRatio="xMidYMid slice"
                />
              </pattern>
            </defs>

            <path
              d={
                "M 4 4 L 0 0 L 0 10 L 4 6 Q 4.502 5.495 4.522 5.002 Q 4.512 4.498 4 4"
              }
              fill={`url(#img-${triangleImageObj.id}-${blockIndex})`}
            />
          </svg>
        )}
      </div>

      <div
        className={`w-full lg:w-2/3 relative flex flex-col justify-between items-start ${
          blockContent.switchOrder ? "sm:flex-row-reverse" : "sm:flex-row"
        } `}
      >
        <div
          ref={scrollAnimRef}
          className={`relative  ${
            blockContent.showMediaOnMobile ? "block" : "hidden lg:block"
          }  w-full sm:w-1/2`}
          style={{
            height: `min(${MEDIA_MAX_HEIGHT}px, calc(${MEDIA_ASPECT_RATIO} * 100vw, 600px)`,
            maxHeight: MEDIA_MAX_HEIGHT + "px",
            maxWidth: ``,
            transform: `scale(${0.6 + yFactor * 0.45})`,
          }}
        >
          {blockContent.mediaFormat === "image" ? (
            <ResponsiveImg image={blockContent.image} imageCrop={false} />
          ) : (
            <BgVideo videoId={blockContent.video} />
          )}
        </div>

        <div
          ref={animRef}
          className={`block w-full lg:w-1/2  ${textAlignClass}  px-6  pb-8 sm:pb-0  ${
            blockContent.showMediaOnMobile ? "lg:mt-0 mt-6" : ""
          }`}
          style={{
            ...fadeSlideIn.transition,
            ...scrollAnimation,
          }}
        >
          <TitleGroup
            blockContent={blockContent}
            textAlignClass={textAlignClass}
          />
          {blockContent.text && (
            <TextBasic
              small={blockContent.smallText}
              color={
                thisPage
                  ? getCascadingColor(
                      cssVariables,
                      siteOverviewData,
                      thisPage,
                      blockContent
                    ).text
                  : "black"
              }
              className={`mt-10  `}
              innerHtml={blockContent.text}
            ></TextBasic>
          )}
          {blockContent.buttons &&
            blockContent.buttons.map((button: any, index: number) => {
              return (
                <Button
                  key={index}
                  link={button.buttonLink}
                  color1={cssVariables.colors[button.buttonColor1]}
                  color2={cssVariables.colors[button.buttonColor2]}
                  flip={blockContent.textAlign === "end"}
                ></Button>
              )
            })}
        </div>
      </div>
    </div>
  )
}

export const TriangleMediaTextBlock_Inputs = {
  key: "triangleMediaTextBlock",
  name: "Triangle / Text /  Media Block",
  tags: ["Text", "Media", "Image", "Video"],
  description: "Three columns with a triangular image, text, and media.",
  inputs: [
    {
      inputType: "expandableInputs",
      label: "Text",
      childInputs: [
        { inputGroupName: "titleInputGroup", type: "group" },
        {
          inputType: "richText",
          label: "Text",
          keyName: "text",
          defaultVal: "",
        },
        {
          inputType: "checkbox",
          label: "Smaller text",
          keyName: "smallText",
        },
        {
          inputType: "radio",
          labelArr: ["Left", "Center", "Right"],
          valueArr: ["start", "center", "end"],
          label: "Text align",
          keyName: "textAlign",
          defaultVal: "center",
        },
        {
          inputType: "radio",
          labelArr: ["Left", "Center", "Right"],
          valueArr: ["start", "center", "end"],
          label: "Text align mobile",
          keyName: "textAlignMobile",
          defaultVal: "center",
        },
      ],
    },
    {
      inputType: "expandableInputs",
      label: "Media",
      childInputs: [
        {
          inputType: "conditionalRadio",
          label: "Media format",
          keyName: "mediaFormat",
          labelArr: ["Video", "Image"],
          valueArr: ["video", "image"],
          childInputsArr: [
            [
              {
                inputType: "video",
                label: "Video",
                keyName: "video",
                defaultVal: "",
              },
            ],
            [
              {
                inputType: "image",
                label: "Image",
                keyName: "image",
                defaultVal: "",
              },
              {
                inputType: "checkbox",
                label: "Animate image on scroll",
                keyName: "imageScrollAnimation",
              },
            ],
          ],
        },
        {
          inputType: "checkbox",
          label: "Show media on mobile",
          keyName: "showMediaOnMobile",
          default: true,
        },
        {
          inputType: "checkbox",
          label: "Media right of text",
          keyName: "switchOrder",
          default: true,
        },
      ],
    },
    {
      inputType: "expandableInputs",
      label: "Triangle",
      childInputs: [
        {
          inputType: "image",
          label: "Triangle Image",
          keyName: "triangleImage",
          defaultVal: "",
        },

        {
          inputType: "checkbox",
          label: "Animate triangle on scroll",
          keyName: "triangleScrollAnimation",
        },
      ],
    },
    {
      inputType: "checkbox",
      label: "Media right of text",
      keyName: "switchOrder",
    },

    { type: "group", inputGroupName: "ctaInputGroup" },
  ],
  defaults: {
    ...BLOCKOPTIONS_DEFAULTS,
  },
}
