import React, { useContext } from "react"
import H3 from "../../atoms/H3"
import TextBasic from "../../atoms/TextBasic"
import { ShopContext } from "../ShopModuleBlock"

type CableOptionProps = {
  upgrade: { [x: string]: any }
  upgradeIndex: number
}

export default function UpgradeBox({
  upgrade,
  upgradeIndex,
}: CableOptionProps) {
  const { cablesOptionIsSelected, setCablesOptionIsSelected } =
    useContext(ShopContext)

  return (
    <>
      <H3 className="font-semibold mt-16 mx-auto fadeIn">{upgrade.title}</H3>
      {upgrade.options?.map((option: any, index: number) => (
        <SelectableOptionBox
          option={option}
          upgradeIndex={upgradeIndex}
          optionIndex={index}
          key={index}
        />
      ))}
    </>
  )
}

function SelectableOptionBox({ option, upgradeIndex, optionIndex }: any) {
  const { selectedOptions, setSelectedOptions } = useContext(ShopContext)

  const selected = selectedOptions[upgradeIndex] === optionIndex

  const handleSelect = () => {
    const newArr = [...selectedOptions]
    newArr[upgradeIndex] = selected ? null : optionIndex
    setSelectedOptions(newArr)
  }

  return (
    <div
      className={`w-full mt-4 px-6 py-6 relative border-2 transition-all duration-300 rounded-md fadeIn flex flex-col sm:flex-row items-center justify-between ${
        selected
          ? "border-[#0066FF] border-inset"
          : "border-slate-300 cursor-pointer opacity-75"
      }`}
      onClick={handleSelect}
    >
      <TextBasic small innerHtml={option.text} className="max-w-xs"></TextBasic>
      <div className="mt-8 sm:mt-0">
        {selected ? (
          <img src="/img/vinkje.svg" className="w-6 h-6" />
        ) : (
          <TextBasic
            small
            className="max-w-xs"
          >{`+ €${option.price}`}</TextBasic>
        )}
      </div>

      <div className="left-0 top-1/2 -translate-x-[13px] -translate-y-3 absolute bg-white">
        <img src={selected ? "/img/minus.svg" : "/img/plus.svg"}></img>
      </div>
    </div>
  )
}
