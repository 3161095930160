import React, { InputHTMLAttributes } from "react"

export default function GreenButton({
  className,
  children,
  ...rest
}: React.HTMLAttributes<HTMLButtonElement>) {
  return (
    <button
      className={`font-workSans text-xl py-2 px-6 rounded-md text-white bg-green-500 hover:bg-green-700 transition-colors focus:outline-none ${className}`}
      {...rest}
    >
      {children}
    </button>
  )
}
