import React, { InputHTMLAttributes } from "react"

export default function TextInput({
  className,
  ...rest
}: React.HTMLAttributes<HTMLInputElement>) {
  return (
    <input
      className={`font-workSans text-xl p-2 rounded-md text-white bg-slate-700 focus:outline-none  block ${className}`}
      {...rest}
    ></input>
  )
}
