import React, { useContext, useState } from "react"
import createFancyPriceStringsFromPriceWithVat from "../../../functions/createFancyPriceStringsFromPriceWithVat"
import AnimatedMenuOpenCloseIcon from "../../atoms/AnimatedMenuOpenCloseIcon"
import H3 from "../../atoms/H3"
import TextBasic from "../../atoms/TextBasic"
import ResponsiveImg from "../../molecules/ResponsiveImg"
import { ShopContext } from "../ShopModuleBlock"
import calculateTotalPrice from "./caculateTotalPrice"
import ShopButton from "./ShopButton"

type Props = {
  orderState: "ordering" | "processing" | "success" | "cancel" | "error"
  setOrderState: Function
}

export default function ConfigurationConfirmBox({
  orderState,
  setOrderState,
}: Props) {
  const {
    blockContent,
    variantIndex,
    typeIndex,
    categoryIndex,
    setIsShowingOrderConfirm,
    totalPrice,
  } = useContext(ShopContext)
  const selectedProduct = {
    ...blockContent.mainCategories[categoryIndex].variants[variantIndex].types[
      typeIndex
    ],
  }

  return (
    <div
      className={`w-full relative p-4 h-[270px] flex justify-between border border-slate-200 transition-colors rounded-sm `}
      style={{
        backgroundColor:
          orderState === "cancel" ||
          orderState === "error" ||
          orderState === "processing"
            ? "#E9E4DE"
            : orderState === "success"
            ? "#4ECB71"
            : "",
      }}
    >
      {orderState === "ordering" && (
        <>
          <div className="flex w-full justify-start">
            <div className="w-[72px] mx-6 lg:mx-12 h-[234px]">
              <ResponsiveImg image={selectedProduct.imageFront} />
            </div>
            <div className="flex flex-col w-full justify-between">
              <div>
                <H3 className="font-semibold">{selectedProduct.name}</H3>
                <TextBasic small innerHtml={selectedProduct.explanationText} />
              </div>
              <div className="self-end flex flex-col items-end p-2">
                <div className="flex items-end">
                  <H3 className="font-semibold">
                    €{createFancyPriceStringsFromPriceWithVat(totalPrice).exVat}
                  </H3>
                  <TextBasic sizeClass="font-xs" className="ml-1">
                    ex. VAT
                  </TextBasic>
                </div>
                <div className="flex items-end mt-1">
                  <TextBasic small className="font-semibold">
                    €
                    {
                      createFancyPriceStringsFromPriceWithVat(totalPrice)
                        .inclVat
                    }
                  </TextBasic>
                  <TextBasic sizeClass="font-xs" className="ml-1">
                    incl. VAT
                  </TextBasic>
                </div>
              </div>
            </div>
          </div>
          <div className="absolute sm:top-1 sm:right-2 right-1 top-0">
            <AnimatedMenuOpenCloseIcon
              isOpen
              onOpen={() => {}}
              onClose={() => setOrderState("cancel")}
            ></AnimatedMenuOpenCloseIcon>
          </div>
        </>
      )}
      {orderState === "cancel" && (
        <>
          <div className="self-center ml-8">
            <H3 className="font-semibold">{blockContent.orderCancelTitle}</H3>
            <TextBasic small className="mb-4">
              {blockContent.orderCancelText}
            </TextBasic>

            <ShopButton
              onClick={() => {
                setOrderState("ordering")
                setIsShowingOrderConfirm(false)
                if (typeof window !== "undefined")
                  window.scrollTo({ left: 0, top: 0, behavior: "auto" })
              }}
              text={blockContent.orderCancelButtonText}
              bgColor="#0066FF"
              textColor="#FFFFFF"
              className="max-w-[200px]"
            />
          </div>
          <div className="absolute sm:top-1 sm:right-2 right-1 top-0 flex items-center justify-end">
            <TextBasic small>{blockContent.closeButtonText}</TextBasic>
            <AnimatedMenuOpenCloseIcon
              isOpen
              onOpen={() => {}}
              onClose={() => setOrderState("ordering")}
            ></AnimatedMenuOpenCloseIcon>
          </div>
        </>
      )}
      {orderState === "success" && (
        <>
          <div className="self-center ml-8">
            <H3 className="font-semibold">{blockContent.orderSuccessTitle}</H3>
            <TextBasic small className="mb-4 max-w-lg">
              {blockContent.orderSuccessText}
            </TextBasic>
          </div>
          <div className="absolute sm:top-1 sm:right-2 right-1 top-0 flex items-center justify-end">
            <TextBasic small>{blockContent.closeButtonText}</TextBasic>
            <AnimatedMenuOpenCloseIcon
              isOpen
              onOpen={() => {}}
              onClose={() => {
                setOrderState("ordering")
                setIsShowingOrderConfirm(false)
                if (typeof window !== "undefined")
                  window.scrollTo({ left: 0, top: 0, behavior: "auto" })
              }}
            ></AnimatedMenuOpenCloseIcon>
          </div>
        </>
      )}
      {orderState === "error" && (
        <>
          <div className="self-center ml-8">
            <H3 className="font-semibold">{blockContent.orderFailTitle}</H3>
            <TextBasic small className="mb-4 max-w-lg">
              {blockContent.orderFailText}
            </TextBasic>
          </div>
          <div className="absolute sm:top-1 sm:right-2 right-1 top-0 flex items-center justify-end">
            <TextBasic small>{blockContent.closeButtonText}</TextBasic>
            <AnimatedMenuOpenCloseIcon
              isOpen
              onOpen={() => {}}
              onClose={() => {
                setOrderState("ordering")
                setIsShowingOrderConfirm(false)
              }}
            ></AnimatedMenuOpenCloseIcon>
          </div>
        </>
      )}
      {orderState === "processing" && (
        <>
          <div className="self-center ml-8">
            <H3 className="font-semibold animate-pulse">
              {blockContent.orderProcessingTitle}
            </H3>
            <TextBasic small className="mb-4 max-w-lg">
              {blockContent.orderProcessingTitle}
            </TextBasic>
          </div>
        </>
      )}
    </div>
  )
}
