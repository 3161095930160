import React, { ReactNode } from "react"

type elementProps = {
  className?: string
  color?: string
  children?: ReactNode
  setInnerHtml?: string
}

export default function H2({
  className,
  color,
  children,
  setInnerHtml,
}: elementProps) {
  return (
    <h2
      style={{ color: color ? color : "inherit" }}
      className={`font-workSans xl:text-5xl lg:text-4xl text-3xl font-semibold m-0 select-none  ${className}`}
      //dangerouslySetInnerHTML={setInnerHtml ? { __html: setInnerHtml } : null}
    >
      {children}
    </h2>
  )
}
