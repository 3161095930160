// import styled from "@emotion/styled"
import React, { useEffect, useState, useRef, Suspense, useContext } from "react"
import { getItemFromId, ImageData } from "@rubendenbrok/bloqmatter-cms"
import getResponsiveImgSrc from "../../functions/getResponsiveImgSrc"
import useIntersection from "../../hooks/useInterSection"
import useSafeWindowEventlistener from "../../hooks/useSafeWindowEventlistener"
import { DataContext } from "../layout"

type ResponsiveBgImgProps = {
  image: string
  imageCrop?: boolean
  yFactor?: number
  fx?: string
  repeat?: boolean
}

export default function ResponsiveBgImg({
  image,
  imageCrop = true,
  fx,
  repeat,
}: ResponsiveBgImgProps) {
  const { processedImageData } = useContext(DataContext)
  const imageObj = getItemFromId<ImageData>(image, processedImageData)
  if (imageObj) {
    if (fx === "zoom" || fx === "parallax")
      return (
        <IntersectionWrapper
          image={imageObj}
          imageCrop={imageCrop}
          fx={fx}
          repeat={repeat}
        />
      )

    return (
      <div className="overflow-hidden w-full h-full relative">
        <BgImage
          image={imageObj}
          imageCrop={imageCrop}
          fx={fx}
          repeat={repeat}
        />
      </div>
    )
  }
  console.warn("Image with ID " + image + " not found!")
  return <></>
}

type BgImgProps = {
  image: ImageData
  imageCrop?: boolean
  yFactor?: number
  fx?: string
  repeat?: boolean
}

function IntersectionWrapper({ image, fx, imageCrop, repeat }: BgImgProps) {
  const [interSectionref, yFactor] = useIntersection()
  const containerRef = useRef<HTMLDivElement | null>(null)
  useEffect(() => {
    if (containerRef.current) {
      interSectionref(containerRef.current)
    }
  }, [containerRef])
  return (
    <div ref={containerRef} className="overflow-hidden w-full h-full relative">
      <BgImage
        image={image}
        imageCrop={imageCrop}
        fx={fx}
        yFactor={yFactor}
        repeat={repeat}
      />
    </div>
  )
}

function BgImage({ image, imageCrop, fx, yFactor, repeat }: BgImgProps) {
  const [loaded, setLoaded] = useState(false)
  const [src, setSrc] = useState("")
  const [fixedBgSize, setFixedBgSize] = useState({ x: 0, y: 0 })
  const imgRef = useRef<any>(null)

  const handleLoad = () => {
    updateFixedBgSize()
    setLoaded(true)
  }

  const updateSrc = () => {
    setLoaded(false)
    const src = getResponsiveImgSrc(image, 766)
    setSrc(src)
    imgRef.current = new Image()
    imgRef.current.onload = handleLoad
    imgRef.current.src = src
    if (typeof window !== "undefined") updateFixedBgSize()
  }

  const updateFixedBgSize = () => {
    const previewIframe =
      document.querySelector<HTMLIFrameElement>("#previewWindow")

    const windowSize =
      previewIframe && previewIframe.contentWindow
        ? {
            x: previewIframe.contentWindow.innerWidth,
            y: previewIframe.contentWindow.innerHeight,
          }
        : { x: window.innerWidth, y: window.innerHeight }
    const imgRatio = image.originalH / image.originalW
    const wRatio = windowSize.y / windowSize.x
    if (imgRatio > wRatio) {
      setFixedBgSize({
        x: windowSize.x,
        y: (windowSize.x / image.originalW) * image.originalH,
      })
    } else {
      setFixedBgSize({
        x: (windowSize.y / image.originalH) * image.originalW,
        y: windowSize.y,
      })
    }
  }

  useSafeWindowEventlistener("resize", updateSrc, [image])
  useEffect(() => {
    updateSrc()
  }, [image])

  return (
    <>
      <div
        style={{
          backgroundImage: `url('${image.urls.webp.placeHolder}'), url('${image.urls.fallback.placeHolder}')`,
          backgroundPosition: `${image.focusX}% 50%`,
          backgroundSize: `${yFactor ? 100 + yFactor * 40 : 100}%`,
          backgroundRepeat: "no-repeat",
        }}
        className="absolute w-full h-full inset-0 blur-xl bg-cover"
      ></div>
      <div
        className="absolute w-full h-full inset-0 transition-opacity duration-700"
        style={{
          opacity: loaded ? 1 : 0,
          backgroundImage: `url('${src}')`,

          backgroundPosition:
            fx === "parallax"
              ? `${image.focusX}% ${yFactor ? 100 - yFactor * 100 : 100}%`
              : `${image.focusX}% ${image.focusY}%`,
          backgroundAttachment: fx === "fixed" ? "fixed" : "",
          backgroundSize:
            fx === "fixed" && !repeat
              ? `${fixedBgSize.x}px ${fixedBgSize.y}px`
              : repeat
              ? "auto"
              : "cover",
          backgroundRepeat: repeat ? "repeat" : "no-repeat",
          transform:
            fx === "zoom" ? `scale(${yFactor ? 1 - yFactor * 0.4 : 1})` : "",
        }}
      ></div>
    </>
  )
}
