import React, { useRef, useEffect, useState, useContext } from "react"
import {
  getItemFromId,
  ImageData,
  VideoData,
} from "@rubendenbrok/bloqmatter-cms"
import { DataContext } from "../layout"
import ResponsiveImg from "../molecules/ResponsiveImg"

type BgVideoProps = {
  videoId: string
}

export default function BgVideo({ videoId }: BgVideoProps) {
  const { processedImageData, videoData } = useContext(DataContext)
  const videoRef = useRef<HTMLVideoElement>(null)
  const [loaded, setLoaded] = useState(false)
  const videoObj = getItemFromId(videoId, videoData)

  function handleLoad() {
    setLoaded(true)
    videoRef.current?.play()
  }

  useEffect(() => {
    if (videoRef.current?.readyState === 4) handleLoad()

    return () => {}
  }, [])

  return (
    <>
      {videoObj && (
        <div className="absolute w-full h-full overflow-hidden inset-0">
          <video
            preload="auto"
            className={"absolute object-cover"}
            style={{
              width: "100%",
              height: "100%",
              objectPosition: "50% 50%",
            }}
            src={videoObj.src}
            muted
            loop
            onCanPlayThrough={handleLoad}
            ref={videoRef}
          ></video>
          {videoObj.thumbnail && !loaded && (
            <ResponsiveImg image={videoObj.thumbnail} imageCrop />
          )}
        </div>
      )}
    </>
  )
}
