import React, { createContext, useEffect } from "react"
import { ImageData, Page, VideoData } from "@rubendenbrok/bloqmatter-cms"
import getCascadingColor, {
  cssVariables,
  inputData,
  WebsiteColorData,
} from "../functions/getCascadingColor"
import Footer from "./molecules/Footer"
import Header from "./header/Header"
import Seo from "./seo"
import DisplayBlock_Container from "./displayBlocks/DisplayBlock_Container"
import TitleBlock_Display from "./displayBlocks/BasicTitleBlock"
import MediaTextBlock_Display from "./displayBlocks/MediaTextBlock"
import BaiscTextBoxBlock_Display from "./displayBlocks/BasicTextBoxBlock"
import HeroBlock_Display from "./displayBlocks/HeroBlock"
import MediaTextTwoColorsBlock_Display from "./displayBlocks/MediaTextTwoColorsBlock"
import NarrowMediaTextBlock_Display from "./displayBlocks/NarrowMediaTextBlock"
import useStore from "../store"
import PopupOverlay from "./atoms/PopupOverlay"
import TitleWithResponsiveTriangleBlock_Display from "./displayBlocks/TitleWithResponsiveTriangleBlock"
import TriangleMediaTextBlock_Display from "./displayBlocks/TriangleMediaTextBlock"
import TriangleTextBlock_Display from "./displayBlocks/TriangleTextBlock"
import CookieConsentPopup from "./CookieConsentPopup"
import ShopModuleBlock_Display from "./displayBlocks/ShopModuleBlock"
import BookYourAppointmentBlock_Display from "./displayBlocks/BookYourAppointmentBlock"

export interface WebsiteDataType {
  thisPage?: Page & inputData
  allPages: Page[]
  cssVariables: cssVariables
  footerData: { [x: string]: any }
  headerData: { [x: string]: any }
  processedImageData: ImageData[]
  videoData: VideoData[]
  seoData: { [x: string]: any }
  siteOverviewData: WebsiteColorData
  localeData: { [x: string]: any }
}

export interface LayoutProps extends WebsiteDataType {
  previewMode?: string
  hoveredBlockIndex?: number
  selectedBlockIndex?: number
  selectFunc?: Function
}

export const DataContext = createContext<WebsiteDataType>({
  allPages: [],
  cssVariables: { colors: [] },
  footerData: [],
  headerData: [],
  processedImageData: [],
  videoData: [],
  seoData: [],
  siteOverviewData: {
    colors_bg: 0,
    colors_text: 0,
    colors_title: 0,
  },
  localeData: {},
})

export default function Layout({
  previewMode,
  hoveredBlockIndex,
  selectedBlockIndex,
  selectFunc,
  thisPage,
  allPages,
  cssVariables,
  footerData,
  headerData,
  processedImageData,
  videoData,
  seoData,
  siteOverviewData,
  localeData,
}: LayoutProps) {
  const popupMsg = useStore(state => state.popupMsg)

  const displayBlocks = {
    titleBlock: TitleBlock_Display,
    mediaTextBlock: MediaTextBlock_Display,
    basicTextBlock: BaiscTextBoxBlock_Display,
    heroBlock: HeroBlock_Display,
    mediaTextTwoColorsBlock: MediaTextTwoColorsBlock_Display,
    narrowMediaTextBlock: NarrowMediaTextBlock_Display,
    titleWithResponsiveTriangleBlock: TitleWithResponsiveTriangleBlock_Display,
    triangleMediaTextBlock: TriangleMediaTextBlock_Display,
    triangleTextBlock: TriangleTextBlock_Display,
    shopModuleBlock: ShopModuleBlock_Display,
    bookYourAppointmentBlock: BookYourAppointmentBlock_Display,
  }

  useEffect(() => {
    if (typeof window !== "undefined" && thisPage) {
      document.body.style.backgroundColor =
        cssVariables.colors[thisPage.pageOptions?.bgColor] ||
        cssVariables.colors[siteOverviewData.colors_bg]
    }
    return () => {}
  }, [])

  if (!thisPage) {
    return <></>
  }

  return (
    <DataContext.Provider
      value={{
        thisPage: thisPage,
        allPages: allPages,
        cssVariables: cssVariables,
        footerData: footerData,
        headerData: headerData,
        processedImageData: processedImageData,
        videoData: videoData,
        seoData: seoData,
        siteOverviewData: siteOverviewData,
        localeData: localeData,
      }}
    >
      <Seo />
      {seoData.enableAnalytics && <CookieConsentPopup />}

      <div
        id="layout"
        className={`w-full h-full overflow-x-hidden relative transform-none max-w-[100vw] pt-12 sm:pt-[80px] ${
          previewMode === "mobile" ? "hidescrollbar" : ""
        }`}
        style={{
          minHeight: "100vh",
          backgroundColor: getCascadingColor(
            cssVariables,
            siteOverviewData,
            thisPage
          ).bg,
          color: getCascadingColor(cssVariables, siteOverviewData, thisPage)
            .text,
        }}
      >
        {thisPage.showHeader && <Header />}
        {thisPage.content?.map((block, index) => {
          if (!(block.key in displayBlocks)) {
            return
          }
          const DisplayBlock =
            displayBlocks[block.key as keyof typeof displayBlocks]
          return (
            <DisplayBlock_Container
              key={index}
              index={index}
              blockContent={block.blockContent}
              hovered={hoveredBlockIndex === index}
              previewMode={previewMode}
              selectFunc={selectFunc}
            >
              {DisplayBlock && (
                <DisplayBlock
                  blockContent={block.blockContent}
                  blockIndex={index}
                />
              )}
            </DisplayBlock_Container>
          )
        })}
        {thisPage.showFooter && footerData && <Footer />}
      </div>
      {popupMsg && <PopupOverlay>{popupMsg.content}</PopupOverlay>}
    </DataContext.Provider>
  )
}
