import React, { useContext } from "react"
import Helmet from "react-helmet"
import { getItemFromId } from "@rubendenbrok/bloqmatter-cms"
import { DataContext } from "./layout"

function Seo() {
  const {
    seoData,
    siteOverviewData,
    thisPage,
    processedImageData,
    localeData,
  } = useContext(DataContext)

  const metaDescription = thisPage?.seoDescription
    ? thisPage.seoDescription
    : seoData.siteDescription
    ? seoData.siteDescription
    : ""

  let title = ""
  if (thisPage?.title) title = thisPage.title
  if (seoData?.siteTitle) title = seoData.siteTitle
  if (seoData?.siteTitle && thisPage?.title)
    title = `${thisPage.title} | ${seoData.siteTitle}`

  const favIconImgObj = siteOverviewData.favicon
    ? getItemFromId(siteOverviewData.favicon, processedImageData)
    : null
  const shareImgObj = siteOverviewData.favicon
    ? getItemFromId(siteOverviewData.favicon, processedImageData)
    : null
  const logoImgObj = siteOverviewData.logoImage
    ? getItemFromId(siteOverviewData.logoImage, processedImageData)
    : null

  const lang = localeData?.currentLocale
    ? localeData.currentLocale
    : localeData?.defaultLocale
    ? localeData.defaultLocale
    : "nl"

  return (
    <Helmet
      htmlAttributes={{
        lang: lang,
      }}
      title={title}
      link={[
        {
          rel: "icon",
          type: "image/png",
          href: favIconImgObj ? favIconImgObj.urls.fallback.thumbnail : "",
        },
      ]}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          name: `author`,
          content: seoData.author || ``,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:image`,
          content: shareImgObj ? shareImgObj.urls.fallback.s : "",
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: seoData.author || ``,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ]}
      script={[
        {
          type: "application/ld+json",
          innerHTML: `
          {
          "@context": "https://schema.org",
          "@type": "Organization",
          "url": "${seoData.siteUrl ? seoData.siteUrl : ""}",
          "logo": "${logoImgObj ? logoImgObj.urls.fallback.m : ""}",
          "name": "${
            seoData.companyName
              ? seoData.companyName
              : seoData.siteTitle
              ? seoData.siteTitle
              : ""
          }",
          "description": "${
            seoData.companyDescription ? seoData.companyDescription : ""
          }",
          "address": {
            "@type": "PostalAddress",
            "addressLocality": "${seoData.country ? seoData.country : ""}",
            "addressRegion": "${seoData.region ? seoData.region : ""}",
            "postalCode": "${seoData.postalCode ? seoData.postalCode : ""}",
            "streetAddress": "${seoData.address ? seoData.address : ""}, ${
            seoData.city ? seoData.city : ""
          }"
          },
          "email": "${seoData.email ? seoData.email : ""}",
          "telephone": "${seoData.telephone ? seoData.telephone : ""}"
        }
        `,
        },
      ]}
    />
  )
}

export default Seo
